.form {
  .formdata {
    display: flex;
    padding: 0 4rem 3rem 4rem;
    border-bottom: 1px solid var(--color__grey-lighter);
  }

  .inputsContainer {
    width: 50%;
  }

  .inputs {
    width: 35rem;
  }

  .permissions {
    width: 50%;
    padding-left: 5rem;
    border-left: 1px solid var(--color__grey-lighter);
  }

  .permissionsLabel {
    color: var(--color__font-dark);
    font-size: 1.4rem;
    font-weight: 600;
    margin: 2.5rem 0;
  }

  .businessRoleSelect {
    select {
      font-size: 1.4rem;
      padding: 1.5rem;
    }
  }
}
