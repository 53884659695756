.labelContainer {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0;

  .icon {
    width: 15px;
    margin-left: 1rem;
  }
}

.helperText {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding-top: 4px;
}

.required {
  color: var(--color__danger);
  margin-left: 1rem;
}

.autocompleteList {
  position: absolute;
  width: 100%;
  z-index: 50;
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  overflow: hidden;
}

.autocompleteItem {
  padding: 1rem 2rem;

  &:hover {
    background: var(--color__grey-lighter);
    cursor: pointer;
  }
}

.autocompleteLink {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__font-dark);
  text-decoration: none;
}
