.textarea {
  background: var(--color__input-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color__font-dark);
  padding: 1.5rem;
  line-height: 1.6;

  &[disabled] {
    color: var(--color__font-subtitle);
  }
}
