.button {
  width: 36rem;
  height: 7rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background: #fff;
  border: 1px solid transparent;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  color: var(--color__font-dark);
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--color__grey-lighter);
  }

  .label {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 2.5rem;
    margin-right: 2.2rem;
    fill: var(--color__grey-dark);
  }

  .chevron {
    width: 1.8rem;
    stroke: var(--color__font-dark);
  }
}
