.modal {
  width: 375px;
  max-width: 95%;
  max-height: 95%;
  background: #fff;
  overflow: hidden;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  .close {
    display: inline-block;
    border: none;
    background: none;
    outline: none;
  }

  .icon {
    width: 18px;
    height: 18px;
    margin: 20px;
    fill: var(--color__black);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    &:hover,
    &:active,
    &:focus {
      fill: var(--color__primary);
    }
  }

  .title {
    font-size: 16px;
    text-align: center;
    padding: 25px;
    font-weight: 600;
  }

  .desc {
    font-size: 14px;
    text-align: center;
    padding: 15px 25px;
  }

  .actions {
    display: flex;
    padding: 25px;
    justify-content: center;
  }

  .qrContainer {
    margin: 5px;
    flex-wrap: wrap;
    justify-content: center;
    width: 315px;
    height: 190px;
    p {
      margin-top: 20px;
      font-size: 12px;
    }
  }

  .oneQrContainer {
    width: 320px;
    height: 215px;
  }
}
