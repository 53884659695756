.modal {
  width: 49rem;
  max-width: 95%;
  background: var(--color__white);
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .close {
    display: inline-block;
    border: none;
    background: none;
    outline: none;
  }

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin: 2rem;
    fill: var(--color__black);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    &:hover,
    &:active,
    &:focus {
      fill: var(--color__primary);
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 3rem 4rem 0 4rem;
  }

  .content {
    padding: 4rem 4rem 3rem 4rem;
  }
}

.withScroll {
  overflow-y: auto;
  max-height: 95%;
  width: 80rem;
}
