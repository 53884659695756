.uploadBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btnFileInput {
    display: none;
  }

  .btnDeleteFile {
    font-size: 1.6rem;
    color: var(--color__primary);
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    font-size: 1.4rem;
  }

  .button {
    padding: 0.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: transparent;

    .icon {
      width: 2rem;
      margin-right: 1rem;
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }

    &.add {
      color: var(--color__primary);
    }
  }
}
