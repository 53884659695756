.container {
  width: 100%;
  thead tr th {
    text-align: left;
  }
  tr:first-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px;
  }
  tr:first-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 5px;
  }

  tr:first-child td:nth-child(2) {
    img {
      position: absolute;
      top: 32px;
      right: 20.5px;
    }
  }
}

.mainTitle {
  font-size: 18px;
  margin-bottom: 15px;
}

.secondTitle {
  font-size: 18px;
  margin: 20px auto;
}

.input {
  width: 40px;
}

.boxesContainer {
  display: flex;
  justify-content: space-between;
  width: 500px;
  p {
    width: 50%;
  }
}

.inactiveStatus {
  color: grey;
}

.BoxesComponent {
  display: flex;
  justify-content: space-between;
}

.addbox {
  width: 180px;
  height: 40px;
  margin-top: 15px;
  font-size: 12px;
  background-color: #dedcdd;
  :hover {
    cursor: pointer;
  }
}

.rightBoxWraper {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.toggleContainer label {
  display: flex;
  justify-content: space-between;
  width: 175px;
  margin-right: 30px;
  p {
    font-size: 12px;
    margin-top: 3px;
    :hover {
      cursor: pointer;
    }
  }
}

.visibility {
  visibility: hidden;
}

.box {
  // border: 1px solid #ebebeb;
  // border-radius: 6px;
  border-top: 0px;
  margin-left: 16px;
  width: 100%;
}

table {
  thead {
    text-align: left;
    background-color: var(--color__grey-lighter);
    border-right: 1px solid var(--color__grey-lighter);
  }
  tr {
    height: 58px;
  }
  td {
    padding-left: 1rem;
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
    position: relative;
  }

  th {
    padding-left: 1rem;
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
  }
  img {
    position: absolute;
    top: 25.5px;
    right: 20.5px;
  }
}

.qrCodeSave {
  color: #f3732f;
  &:hover {
    cursor: pointer;
  }
}

button::before {
  margin-left: 20px;
}

.addBox {
  margin: 2rem 1rem 3rem 1.5rem;
}

.titleFont {
  font-size: 14px;
  letter-spacing: 0.12px;
  color: var(--color__font-dark);
  font-weight: 600;

  > div {
    white-space: nowrap;
  }
}

.boxSelectContainer {
  display: flex;
  align-items: center;

  > span {
    margin-right: 5px;
  }

  .boxSelect {
    background: var(--color__input-bg);
    border: 1px solid transparent;
    border-radius: var(--border-radius__md);
    font-size: 1.2rem;
    color: var(--color__font-dark);
    padding: 1rem 0;
  }
}

.renewKey {
  max-width: 50px;
}
