.mainContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.container {
  border-radius: 6px;

  tr:last-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    //height: 50px;
  }
  tr:last-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    //height: 5px;
  }

  tr:first-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px;
  }
  tr:first-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    //height: 5px;
  }
  tr td:first-child {
    font-size: 14px;
    letter-spacing: 0.12px;
    color: var(--color__font-dark);
    font-weight: 600;
    width: 100px;
  }
}

table {
  border-collapse: collapse;
  // box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  border-radius: var(--border-radius__lg) var(--border-radius__lg) 0 0;
  thead {
    text-align: left;
    background-color: var(--color__grey-lighter);
    border-right: 1px solid var(--color__grey-lighter);
  }
  tbody {
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-radius: 0px 0px 6px;
    border-collapse: separate;
  }
  tr {
    height: 58px;
  }

  td {
    padding-right: 1rem;
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
    margin-left: 0px;
  }

  th {
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
    margin-left: 0px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}

.threeRowcontainer {
  border: 1px solid #ebebeb;
  thead tr th {
    width: 33.33333%;
    text-align: left;
  }

  td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 20px;
  }
  td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px;
  }
  tr td:first-child {
    font-size: 14px;
    letter-spacing: 0.12px;
    color: var(--color__font-dark);
    font-weight: 600;
  }
}

.mainTitle {
  font-size: 18px;
  margin-bottom: 15px;
}

.secondTitle {
  font-size: 16px;
}

.input {
  width: 50px;
}

.boxesContainer {
  display: flex;
  justify-content: space-between;
  p {
    width: 50%;
  }
}

.inactiveStatus {
  color: grey;
}

.BoxesComponent {
  display: flex;
  justify-content: space-between;
}

.priceInput input {
  width: 80px;
}
.pricePlanInput input {
  width: 80px;
}

.threeRowPriceInput input {
  width: 80px;
}
