.button {
  --button__dark-bg: var(--color__grey-darker);
  --button__dark-hover: #484849;
}

.button {
  background: none;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  cursor: pointer;
  padding: 0;
  width: 2.6rem;

  .icon {
    width: 100%;
    fill: var(--button__dark-bg);
  }

  &[disabled] {
    cursor: auto;
  }

  &:focus {
    outline: none;
  }

  &:hover .icon,
  &:focus .icon,
  &:active .icon {
    fill: var(--button__dark-hover);
  }
}
