.desc {
  margin-top: -28px;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 18px;
}

.actions {
  display: flex;
  justify-content: space-between;
}
