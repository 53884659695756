.document {
  padding: 4rem;
}

.info {
  margin: 4rem 0 0 0;
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 15rem;
      font-weight: 600;
    }
  }
}

.editMembershipRow {
  text-align: left;

  > span {
    &:last-child {
      display: inline-flex;
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
}

.topUpRow {
  display: flex;
  flex-direction: row;
  width: 65%;
  column-gap: 2rem;
}

.membershipRow {
  display: flex;
  align-items: start;
}

.membershipItem {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.membershipRowTitle {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.25;
  width: 15rem;
  padding-top: 0.5rem;
}
.membershipTitle {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  width: 24.5rem;
}

.addMembershipContainer {
  margin-top: 2.4rem;
  > button {
    padding-left: 0;
  }
}

.tableHeader {
  padding: 1.5rem;
}

.leftHeaderItemsContainer {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between;
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;
}
