.desc {
  font-size: 1.4rem;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;
}

.downloadText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
