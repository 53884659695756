.tooltip {
  --tooltip__color: #fff;
  --tooltip__bg-color: #000;
  position: relative;
}

.tooltip {
  margin: 0 1.5rem;

  .tip {
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    padding: 0.5rem 1rem;
    text-align: center;
    color: var(--tooltip__color);
    background-color: var(--tooltip__bg-color);
    opacity: 0.8;
    border-radius: 0.5rem;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 5px solid transparent;
      border-top-color: var(--tooltip__bg-color);
    }
  }

  &:hover .tip {
    visibility: visible;
  }
}
