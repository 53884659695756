.header {
  width: 100%;
  height: var(--size__header-height);
  background: linear-gradient(
    270deg,
    var(--color__font-title) 0%,
    var(--color__font-dark) 100%
  );
  display: flex;
  align-items: center;
}

.logo {
  width: var(--size__sidebar-width);
}

.nav {
  flex: 1;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:only-child {
    margin-left: auto;
  }
}
