.refreshIcon {
  width: 20px;
  height: 20px;
  margin: auto;
  :hover {
    cursor: pointer;
  }
}

.rotateRefreshIcon {
  animation: rotate-keyframes 3s;
  width: 20px;
  height: 20px;
  margin: auto;
}

@keyframes rotate-keyframes {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1440deg);
  }
}
