.paper {
  background: #fff;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;

  &.xs {
    width: var(--content-width__xs);
  }

  &.sm {
    width: var(--content-width__sm);
  }

  &.lg {
    width: var(--content-width__lg);
  }
}

.container {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem 2rem 4rem;
}

.nav {
  margin-left: 2rem;
}

.note {
  margin-left: 2rem;
  background: #fff;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}
