.container {
  width: var(--size__page-width);
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex: 1;
  display: flex;
}

.main {
  padding: 3rem;
  flex: 1;
}

.impersonationContainer {
  width: 22rem;
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  margin-bottom: 4rem;
}
