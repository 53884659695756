.googleMapsWindow {
  margin-top: 10px;
  height: 300px;
  width: 300px;
  border: 1px solid grey;
}

.mapsInput {
  width: 100%;
}

.searchBox {
  width: 100%;
}

.underSearchBox {
  // height: 65px;
  // width: 520px;
  // top: 289px;
  // background-color: #fff;
  // position: absolute;
  p {
    font-size: 1.4rem;
    margin-top: 15px;
    color: #1d1c1c;
  }
  // z-index: 3;
}

.mapSearchInput {
  background: var(--color__input-bg);
  border: 2px solid transparent;
  border-radius: var(--border-radius__md);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color__font-dark);
  padding: 1.5rem;
  // margin-top: -4px;
  z-index: 10;
  outline: 0;
}

.mapContainer {
  position: relative;
}

.mapElement {
  position: relative;
  margin-top: 15px;
  height: 320px;
  border-radius: var(--border-radius__md);
  z-index: 2;
  background: #fff;
}

.searchBoxWrapper {
  background: #fff;
}
