.boxHeader {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color__grey-lighter);
  padding-top: 12px;
  padding-bottom: 12px;

  p {
    font-size: 14px;
    margin-top: 15px;
  }
}

.serviceItem {
  display: flex;
  color: var(--color__font-dark);
  .label {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    letter-spacing: 0.12px;
  }
}

.serviceItemOpened {
  display: flex;
  color: #f3732f;
  :nth-of-type(1) {
    fill: #f3732f;
  }
  .label {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    letter-spacing: 0.12px;
  }
}

.iconContainer {
  width: 33px;
  :first-child {
    margin-left: auto;
    margin-right: auto;
  }
}
