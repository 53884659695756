.container {
  position: relative;
  z-index: 10;
}

.filter {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial;

  &.left {
    left: -0.5rem;
  }

  &.right {
    right: -0.5rem;
  }
}

.range {
  width: 100%;
  display: flex;
}

.input {
  width: 50%;
  outline: none;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  padding: 1.6rem 2rem;

  &:first-child {
    border-right: 1px solid var(--color__grey-lighter);
    border-top-left-radius: var(--border-radius__md);
  }
  &:last-child {
    border-top-right-radius: var(--border-radius__md);
  }

  &::-webkit-clear-button {
    display: none;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;

  .button {
    padding: 0.5rem 4rem;
    width: 12rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
}
