.container {
  position: relative;
  z-index: 11;
}

.title {
  display: flex;
  align-items: center;
  padding: 1.5rem;

  .icon {
    width: 1rem;
    margin-left: 0.5rem;
  }
}

.filter {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial;

  &.left {
    left: -0.5rem;
  }

  &.right {
    right: -0.5rem;
  }
}

.datepicker {
  width: 100%;
  display: flex;

  > div {
    &:first-child {
      border-right: 1px solid var(--color__grey-lighter);
    }
  }

  .input {
    &:first-child {
      border-top-left-radius: var(--border-radius__md);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius__md);
    }
    font-size: 1.2rem;
  }
}

.input {
  width: 100%;
  outline: none;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  padding: 1.6rem 2rem;
}

.ranges {
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;

  .button {
    padding: 0.5rem 4rem;
    width: 12rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.locationsHeader {
  font-size: 14px;
  text-align: center;
  margin-top: 14px;
  // margin-left: 20px;
  padding-bottom: 20px;
}

.rangeTitle {
  width: 151px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  padding: 0;
}
