.button {
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;

  .icon {
    width: 2rem;
    margin-right: 1rem;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &.add {
    color: var(--color__primary);
  }

  &.remove {
    color: var(--color__font-dark);
  }

  &.filter {
    color: var(--color__primary);
    .icon {
      width: 1.2rem;
      fill: var(--color__primary);
    }
  }

  &.text-big {
    font-size: 2.5rem;
  }
}
