.document {
  padding: 4rem;
}

.info {
  margin: 1rem 0 0 0;
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 16rem;
      font-weight: 600;
    }
    &:not(:first-child) {
      width: 20rem;
    }
  }
}

.tableHeader {
  padding: 1.5rem;
}

.leftHeaderItemsContainer {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between;
}

.submitContainer {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2rem 2rem 0;
}

.physicalCardToggler {
  display: flex;
  justify-content: end;
  padding: 10px 25px 0px 0px;
}
