.login {
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  .button {
    margin: 4rem auto 0 auto;
  }
}

.container {
  padding: 4rem;
}

.logo {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
