.list {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
}

.title {
  color: var(--color__font-subtitle);
  text-decoration: none;

  &:hover {
    color: var(--color__font-title);
  }
}

.subtitle {
  display: flex;
  align-items: center;
  color: var(--color__font-subtitle);

  .icon {
    width: 1.5rem;
    stroke: var(--color__font-subtitle);
    margin: 0 1rem;
  }
}
