.secondary {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem;
}

.document {
  padding: 4rem;
}

.info {
  margin: 4rem 0;
}

.infoRow {
  margin-bottom: 3rem;
  display: flex;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  > span {
    &:first-child {
      width: 16rem;
      font-weight: 600;
    }
  }
}

.bold {
  font-weight: 600;
}

.tableHeader {
  margin-left: 1.5rem;
}

.tableFooter {
  text-align: right;
  padding: 2rem 0 0 0;
}

.summary {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  margin-bottom: 2rem;

  > span {
    &:last-child {
      display: inline-block;
      min-width: 7rem;
      margin-left: 1.5rem;
    }
  }
}

.discountSummary {
  color: var(--color__font-dark);
  font-size: 1.4rem;

  > span {
    &:last-child {
      display: inline-block;
      min-width: 7rem;
      margin-left: 1.5rem;
    }
  }
}

.labelContainer {
  padding: 0rem 0 1rem 0;
  text-align: left;
}

.submit {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.colorGrey {
  color: var(--color__grey);
}

.paymentInfoTitle {
  margin: 2rem 0rem;
}
