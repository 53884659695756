.main {
  display: inline-block;
  position: relative;
}

.table {
  display: table;
  font-size: 1.4rem;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: var(--border-radius__lg) var(--border-radius__lg) 0 0;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);

  &.xs {
    min-width: var(--content-width__xs);
  }
  &.sm {
    min-width: var(--content-width__sm);
  }
  &.lg {
    min-width: var(--content-width__lg);
  }
}

.header {
  display: table-header-group;
}

.headerRow {
  display: table-row;
  text-align: left;
  font-weight: 600;
  color: var(--color__font-dark);
  background: var(--color__table-header-bg);
}

.headerCell {
  display: table-cell;
  vertical-align: middle;

  &:first-child {
    border-top-left-radius: var(--border-radius__lg);
  }
  &:last-child {
    border-top-right-radius: var(--border-radius__lg);
  }

  .headerTitle {
    padding: 1.5rem;
  }

  &:hover {
    background: var(--color__grey-light);
    cursor: pointer;
  }
}

.body {
  display: table-row-group;
  background: var(--color__white);

  .separatorRow {
    display: table-row;
    background-color: var(--color__table-footer-bg);
    height: 30px;
  }
}

.bodyRow {
  display: table-row;
  color: var(--color__font-dark);
  text-decoration: none;
  border-bottom: 1px solid var(--color__grey-lighter);

  &:hover {
    background: var(--color__grey-lighter);
    cursor: pointer;
  }

  &.font-big {
    font-size: 2.5rem;
  }
}

.bodyCell {
  display: table-cell;
  padding: 1.5rem;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.footerRow {
  display: table-row;
  height: 3.5rem;
  background: var(--color__table-footer-bg);
  text-align: left;
  font-weight: 600;
}

.footerCell {
  display: table-cell;
  padding: 1.5rem;
  background: var(--color__table-footer-bg);

  position: sticky;
  bottom: 0;

  &.rightCells {
    text-align: right;
  }
}

.emptyRow {
  display: table-row;
  height: 6rem;
  background: #fff;
}

.infoText {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.verticalCenter {
  vertical-align: middle;
}

.disabled {
  background: var(--color__grey-lighter);
}
