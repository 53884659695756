.button {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: var(--border-radius__sm);
  cursor: pointer;
  background: var(--color__input-bg);
  flex: 1;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:active,
  &.active {
    outline: none;
    background: var(--color__primary);
  }
}
