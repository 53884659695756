@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap);
:root {
  --color__primary: #f1712c;
  --color__primary-disabled: #fdac81;
  --color__dim: #fde9de;
  --color__white: #fff;
  --color__black: #000;
  --color__grey-lighter: #ebeaeb;
  --color__grey-light: #aeaeaf;
  --color__grey: #6a696b;
  --color__grey-dark: #48474b;
  --color__grey-darker: #373738;
  --color__sidebar-bg: #fefefe;
  --color__font-dark: #1d1c1c;
  --color__font-title: #404042;
  --color__font-subtitle: #8a8789;
  --color__table-header-bg: #eeedee;
  --color__table-footer-bg: #f6f6f8;
  --color__input-bg: #f2f1f1;
  --color__border: #a5a4a4;
  --color__success: #00a63c;
  --color__success-bg: #e6f6ec;
  --color__danger: #d22d2d;
  --color__danger-bg: #f7d6d7;
  --color__warning: #fab600;
  --color__status-blue: #00b6da;
  --color__status-green: #00a63c;
  --color__status-red: #f20000;
  --color__status-orange: #ff6a1b;
  --color__status-yellow: #f2b920;
  --color__status-dark-grey: #1d1c1c;
  --color__status-dark-green: #007028;
  --size__page-width: 154rem;
  --size__sidebar-width: 22rem;
  --size__header-height: 8rem;
  --border-radius__sm: 4px;
  --border-radius__md: 6px;
  --border-radius__lg: 8px;
  --content-width__xs: 50rem;
  --content-width__sm: 88rem;
  --content-width__lg: 100%; }

*,
*::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  box-sizing: border-box;
  line-height: 1; }

body {
  font-family: 'Open Sans', sans-serif;
  background: var(--color__grey-lighter); }

html {
  -webkit-text-size-adjust: 100%; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  background-color: transparent; }

a,
button {
  -webkit-tap-highlight-color: transparent; }

b,
strong {
  font-weight: bolder; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

small {
  font-size: 75%; }

img {
  border-style: none;
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

label {
  display: inline-block; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit; }

select {
  word-wrap: normal; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

[hidden] {
  display: none; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: '';
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  top: -1px;
  /* border-bottom-color: #aeaeae; */
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12.8px;
  background-color: #fff;
  color: #000;
  /* border: 1px solid #aeaeae; */
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 4.8px;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 4.8px;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50%;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^='top'] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^='right'] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^='left'] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  /* border-bottom: 1px solid #aeaeae; */
  border-top-left-radius: 4.8px;
  border-top-right-radius: 4.8px;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: normal;
  font-size: 15.1px;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 27.2px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 7.2px solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 6.4px;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text {
  display: inline-block;
  width: 64px;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  width: 85px;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time']::-webkit-inner-spin-button,
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input[type='time'] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 4.8px;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (27.2px / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f1712c;
  color: white;
  font-weight: normal;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #f1712c;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 27.2px;
  line-height: 27.2px;
  text-align: center;
  margin: 2.65px;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 4.8px;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  font-weight: normal;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 27.2px;
  line-height: 27.2px;
  text-align: center;
  margin: 2.65px;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
  border-radius: 4.8px;
  background-color: #f1712c;
  color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover {
  background-color: #c35d25;
}

.react-datepicker__month--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 4.8px;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
  font-weight: normal;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
  border-radius: 4.8px;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 4.8px;
  background-color: #f1712c;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  background-color: #c35d25;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  border-radius: 4.8px;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #c35d25;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #f1712c;
}

.react-datepicker__month-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 4.8px;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
  .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
  .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
  .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 55px;
  top: 11px;
  position: relative;
  border-width: 7.2px;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 4.8px;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 4.8px;
  border-top-right-radius: 4.8px;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 4.8px;
  border-bottom-right-radius: 4.8px;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover
  .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
  .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover
  .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover
  .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
  .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover
  .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon::after {
  background-color: #f1712c;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: '\00d7';
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: normal;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 48px;
  line-height: 48px;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 32px;
    line-height: 32px;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 23px;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 13px solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal
  .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__year-read-view--selected-year {
  display: block;
  padding: 10px 0 2px 0;
  font-weight: normal;
}

.alert_alert__2N2Dj {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  margin: 0.4rem 0;
  border-radius: var(--border-radius__sm);
  display: inline-block; }
  .alert_alert__2N2Dj .alert_icon__3EdJU {
    display: inline-block;
    width: 1.5rem;
    margin-right: 1rem; }
  .alert_alert__2N2Dj.alert_success__h4kO2 {
    color: var(--color__success);
    background: var(--color__success-bg); }
  .alert_alert__2N2Dj.alert_danger__1Wbni {
    color: var(--color__danger);
    background: var(--color__danger-bg); }

.backdrop_backdrop__BoU-m {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50; }

.breadcrumb_list__2SfQ_ {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600; }

.breadcrumb_title__3OwPM {
  color: var(--color__font-subtitle);
  text-decoration: none; }
  .breadcrumb_title__3OwPM:hover {
    color: var(--color__font-title); }

.breadcrumb_subtitle__2YvY1 {
  display: flex;
  align-items: center;
  color: var(--color__font-subtitle); }
  .breadcrumb_subtitle__2YvY1 .breadcrumb_icon__1KhFM {
    width: 1.5rem;
    stroke: var(--color__font-subtitle);
    margin: 0 1rem; }

.icon_icon__10kQG {
  display: block;
  height: 100%; }

.button_button__2wKvk {
  --button__primary-text: #fff;
  --button__primary-bg: var(--color__primary);
  --button__primary-hover: #eb5d10;
  --button__primary-disabled-bg: var(--color__primary-disabled);
  --button__dim-text: var(--color__primary);
  --button__dim-bg: var(--color__dim);
  --button__dim-hover: #fcdece;
  --button__light-text: var(--color__grey-darker);
  --button__light-bg: #d4d2d3;
  --button__light-hover: #c3c1c2;
  --button__dark-text: #fff;
  --button__dark-bg: var(--color__grey-darker);
  --button__dark-hover: #484849;
  --button__outline-text: var(--color__grey-darker);
  --button__outline-hover: rgba(0, 0, 0, 0.05); }

.button_button__2wKvk {
  padding: 1.4rem 4.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button_button__2wKvk .button_icon__2qmpL {
    width: 2rem;
    margin-right: 1rem; }
  .button_button__2wKvk:focus {
    outline: none; }
  .button_button__2wKvk.button_primary__KwO3T {
    color: var(--button__primary-text);
    background: var(--button__primary-bg); }
    .button_button__2wKvk.button_primary__KwO3T .button_icon__2qmpL {
      stroke: var(--button__primary-text); }
    .button_button__2wKvk.button_primary__KwO3T:hover, .button_button__2wKvk.button_primary__KwO3T:focus, .button_button__2wKvk.button_primary__KwO3T:active {
      background: var(--button__primary-hover); }
  .button_button__2wKvk.button_dim__3y5mZ {
    color: var(--button__dim-text);
    background: var(--button__dim-bg); }
    .button_button__2wKvk.button_dim__3y5mZ .button_icon__2qmpL {
      stroke: var(--button__dim-text); }
    .button_button__2wKvk.button_dim__3y5mZ:hover, .button_button__2wKvk.button_dim__3y5mZ:focus, .button_button__2wKvk.button_dim__3y5mZ:active {
      background: var(--button__dim-hover); }
  .button_button__2wKvk.button_light__1iBcq {
    color: var(--button__light-text);
    background: var(--button__light-bg); }
    .button_button__2wKvk.button_light__1iBcq .button_icon__2qmpL {
      stroke: var(--button__light-text); }
    .button_button__2wKvk.button_light__1iBcq:hover, .button_button__2wKvk.button_light__1iBcq:focus, .button_button__2wKvk.button_light__1iBcq:active {
      background: var(--button__light-hover); }
  .button_button__2wKvk.button_dark__3Q_X4 {
    color: var(--button__dark-text);
    background: var(--button__dark-bg); }
    .button_button__2wKvk.button_dark__3Q_X4 .button_icon__2qmpL {
      stroke: var(--button__dark-text); }
    .button_button__2wKvk.button_dark__3Q_X4:hover, .button_button__2wKvk.button_dark__3Q_X4:focus, .button_button__2wKvk.button_dark__3Q_X4:active {
      background: var(--button__dark-hover); }
  .button_button__2wKvk.button_outline__fnvuO {
    color: var(--button__outline-text);
    border: 1px solid var(--button__outline-text);
    background: transparent; }
    .button_button__2wKvk.button_outline__fnvuO .button_icon__2qmpL {
      stroke: var(--button__outline-text); }
    .button_button__2wKvk.button_outline__fnvuO:hover, .button_button__2wKvk.button_outline__fnvuO:focus, .button_button__2wKvk.button_outline__fnvuO:active {
      background: var(--button__outline-hover); }
  .button_button__2wKvk:disabled {
    background: var(--button__primary-disabled-bg); }
    .button_button__2wKvk:disabled:hover {
      background: var(--button__primary-disabled-bg);
      cursor: default; }

.button_transparent__1hctv {
  background: transparent;
  color: black;
  text-decoration: underline;
  width: 50px; }

.buttonLink_button__Coqfb {
  width: 36rem;
  height: 7rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background: #fff;
  border: 1px solid transparent;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  color: var(--color__font-dark);
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .buttonLink_button__Coqfb:hover {
    background: var(--color__grey-lighter); }
  .buttonLink_button__Coqfb .buttonLink_label__J3HV_ {
    display: flex;
    align-items: center; }
  .buttonLink_button__Coqfb .buttonLink_icon__1Pdlv {
    width: 2.5rem;
    margin-right: 2.2rem;
    fill: var(--color__grey-dark); }
  .buttonLink_button__Coqfb .buttonLink_chevron__1IJRD {
    width: 1.8rem;
    stroke: var(--color__font-dark); }

.buttonSimple_button__3a6bJ {
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent; }
  .buttonSimple_button__3a6bJ .buttonSimple_icon__E-vpt {
    width: 2rem;
    margin-right: 1rem; }
  .buttonSimple_button__3a6bJ:focus {
    outline: none; }
  .buttonSimple_button__3a6bJ:hover, .buttonSimple_button__3a6bJ:active, .buttonSimple_button__3a6bJ:focus {
    text-decoration: underline; }
  .buttonSimple_button__3a6bJ.buttonSimple_add__1mqfB {
    color: var(--color__primary); }
  .buttonSimple_button__3a6bJ.buttonSimple_remove__1dnMU {
    color: var(--color__font-dark); }
  .buttonSimple_button__3a6bJ.buttonSimple_filter__3TIov {
    color: var(--color__primary); }
    .buttonSimple_button__3a6bJ.buttonSimple_filter__3TIov .buttonSimple_icon__E-vpt {
      width: 1.2rem;
      fill: var(--color__primary); }
  .buttonSimple_button__3a6bJ.buttonSimple_text-big__fvLSf {
    font-size: 2.5rem; }

.buttonDownload_button__2b8x8 {
  --button__dark-bg: var(--color__grey-darker);
  --button__dark-hover: #484849; }

.buttonDownload_button__2b8x8 {
  background: none;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  cursor: pointer;
  padding: 0;
  width: 2.6rem; }
  .buttonDownload_button__2b8x8 .buttonDownload_icon__1yVBd {
    width: 100%;
    fill: var(--button__dark-bg); }
  .buttonDownload_button__2b8x8[disabled] {
    cursor: auto; }
  .buttonDownload_button__2b8x8:focus {
    outline: none; }
  .buttonDownload_button__2b8x8:hover .buttonDownload_icon__1yVBd,
  .buttonDownload_button__2b8x8:focus .buttonDownload_icon__1yVBd,
  .buttonDownload_button__2b8x8:active .buttonDownload_icon__1yVBd {
    fill: var(--button__dark-hover); }

.buttonFileUplaod_uploadBtnContainer__2-45a {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_btnFileInput__1u19I {
    display: none; }
  .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_btnDeleteFile__hUxG2 {
    font-size: 1.6rem;
    color: var(--color__primary);
    margin-left: 2rem; }
    .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_btnDeleteFile__hUxG2:hover {
      cursor: pointer; }
  .buttonFileUplaod_uploadBtnContainer__2-45a span {
    font-size: 1.4rem; }
  .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW {
    padding: 0.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: transparent; }
    .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW .buttonFileUplaod_icon__D4Gap {
      width: 2rem;
      margin-right: 1rem; }
    .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW:focus {
      outline: none; }
    .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW:hover, .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW:active, .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW:focus {
      text-decoration: underline; }
    .buttonFileUplaod_uploadBtnContainer__2-45a .buttonFileUplaod_button__2HpPW.buttonFileUplaod_add__1QWLg {
      color: var(--color__primary); }

.checkbox_checkbox__3V9f8 {
  line-height: 22px;
  margin: 1.5rem 0; }
  .checkbox_checkbox__3V9f8 input[type='checkbox'] {
    opacity: 0; }
    .checkbox_checkbox__3V9f8 input[type='checkbox'] + label::after {
      content: none; }
    .checkbox_checkbox__3V9f8 input[type='checkbox']:checked + label::before {
      background: var(--color__primary);
      border: 1px solid var(--color__primary); }
    .checkbox_checkbox__3V9f8 input[type='checkbox']:checked + label::after {
      content: ''; }
    .checkbox_checkbox__3V9f8 input[type='checkbox']:focus + label::before {
      outline: 5px auto -webkit-focus-ring-color; }
  .checkbox_checkbox__3V9f8 .checkbox_labelFullLine__18A5b {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 1.4rem;
    color: var(--color__font-dark);
    left: -7px; }
    .checkbox_checkbox__3V9f8 .checkbox_labelFullLine__18A5b.checkbox_success__20blB {
      font-weight: bold; }
  .checkbox_checkbox__3V9f8 label:not(.checkbox_labelFullLine__18A5b) {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 1.4rem;
    color: var(--color__font-dark);
    left: -7px;
    max-width: 300px; }
    .checkbox_checkbox__3V9f8 label:not(.checkbox_labelFullLine__18A5b).checkbox_success__20blB {
      font-weight: bold; }
  .checkbox_checkbox__3V9f8 label::before {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border: 1px solid var(--color__border);
    border-radius: 2px;
    position: absolute;
    top: 3px;
    left: 0px; }
  .checkbox_checkbox__3V9f8 label::after {
    content: '';
    display: inline-block;
    height: 5px;
    width: 9px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 8px;
    left: 5px; }

.dateFilter_container__1Q9dF {
  position: relative;
  z-index: 11; }

.dateFilter_title__2Wh25 {
  display: flex;
  align-items: center;
  padding: 1.5rem; }
  .dateFilter_title__2Wh25 .dateFilter_icon__8Tvd3 {
    width: 1rem;
    margin-left: 0.5rem; }

.dateFilter_filter__1VIPn {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial; }
  .dateFilter_filter__1VIPn.dateFilter_left__2wMOS {
    left: -0.5rem; }
  .dateFilter_filter__1VIPn.dateFilter_right__23_cM {
    right: -0.5rem; }

.dateFilter_datepicker__2wOC3 {
  width: 100%;
  display: flex; }
  .dateFilter_datepicker__2wOC3 > div:first-child {
    border-right: 1px solid var(--color__grey-lighter); }
  .dateFilter_datepicker__2wOC3 .dateFilter_input__2IsL3 {
    font-size: 1.2rem; }
    .dateFilter_datepicker__2wOC3 .dateFilter_input__2IsL3:first-child {
      border-top-left-radius: var(--border-radius__md); }
    .dateFilter_datepicker__2wOC3 .dateFilter_input__2IsL3:last-child {
      border-top-right-radius: var(--border-radius__md); }

.dateFilter_input__2IsL3 {
  width: 100%;
  outline: none;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  padding: 1.6rem 2rem; }

.dateFilter_ranges__3KAek {
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap; }

.dateFilter_actions__33qRw {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem; }
  .dateFilter_actions__33qRw .dateFilter_button__2IDIb {
    padding: 0.5rem 4rem;
    width: 12rem; }
    .dateFilter_actions__33qRw .dateFilter_button__2IDIb:first-child {
      margin-right: 1rem; }

.dateFilter_locationsHeader__1qwW- {
  font-size: 14px;
  text-align: center;
  margin-top: 14px;
  padding-bottom: 20px; }

.dateFilter_rangeTitle__2jk5s {
  width: 151px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  padding: 0; }

.rangeButton_button__3F5eE {
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: var(--border-radius__sm);
  cursor: pointer;
  background: var(--color__input-bg);
  flex: 1 1;
  white-space: nowrap; }
  .rangeButton_button__3F5eE:hover, .rangeButton_button__3F5eE:focus, .rangeButton_button__3F5eE:active, .rangeButton_button__3F5eE.rangeButton_active__7C7u6 {
    outline: none;
    background: var(--color__primary); }

.filterTitle_title__3QbOs {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  max-width: 12rem; }
  .filterTitle_title__3QbOs > span {
    word-wrap: break-word; }
  .filterTitle_title__3QbOs .filterTitle_icon__zUJL2 {
    width: 1rem;
    margin-left: 0.5rem;
    fill: var(--color__font-dark); }
  .filterTitle_title__3QbOs.filterTitle_active__2JUAD .filterTitle_icon__zUJL2 {
    fill: var(--color__primary); }

.header_header__3wy06 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  height: 4.5rem; }
  .header_header__3wy06.header_lg__2YjKC {
    width: var(--content-width__lg); }
  .header_header__3wy06.header_sm__1g0KE {
    width: var(--content-width__sm); }
  .header_header__3wy06.header_custom__155ns {
    width: 68%; }

.headerActions_actions__2D77N {
  display: flex;
  align-items: center; }
  .headerActions_actions__2D77N > * {
    margin-left: 1.5rem; }

.input_labelContainer__35ayQ {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0; }
  .input_labelContainer__35ayQ .input_icon__1OvYu {
    width: 15px;
    margin-left: 1rem; }

.input_helperText__1_Aaa {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding-top: 4px; }

.input_required__3rRb7 {
  color: var(--color__danger);
  margin-left: 1rem; }

.input_autocompleteList__qe-3a {
  position: absolute;
  width: 100%;
  z-index: 50;
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  overflow: hidden; }

.input_autocompleteItem__29bAV {
  padding: 1rem 2rem; }
  .input_autocompleteItem__29bAV:hover {
    background: var(--color__grey-lighter);
    cursor: pointer; }

.input_autocompleteLink__3Ii17 {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__font-dark);
  text-decoration: none; }

.inputElement_input__2M1FD {
  background: var(--color__input-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color__font-dark);
  padding: 1.5rem; }
  .inputElement_input__2M1FD[disabled] {
    color: var(--color__font-subtitle); }

.textareaElement_textarea__2H5ap {
  background: var(--color__input-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color__font-dark);
  padding: 1.5rem;
  line-height: 1.6; }
  .textareaElement_textarea__2H5ap[disabled] {
    color: var(--color__font-subtitle); }

.labelElement_label__1_x9Q {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600; }

.monthSelection_container__2sgpM {
  width: 80px;
  height: 50px;
  background: var(--color__input-bg);
  border: 1px solid transparent; }

.paper_paper__NILcm {
  background: #fff;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem; }
  .paper_paper__NILcm.paper_xs__12piT {
    width: var(--content-width__xs); }
  .paper_paper__NILcm.paper_sm__2W6pm {
    width: var(--content-width__sm); }
  .paper_paper__NILcm.paper_lg__3LPVJ {
    width: var(--content-width__lg); }

.paper_container__ETdRY {
  display: flex; }

.paper_header__1Ksi1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 4rem 2rem 4rem; }

.paper_nav__35VSA {
  margin-left: 2rem; }

.paper_note__3fNxY {
  margin-left: 2rem;
  background: #fff;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem; }

.rangeFilter_container__3nJ9O {
  position: relative;
  z-index: 10; }

.rangeFilter_filter__1xIXF {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial; }
  .rangeFilter_filter__1xIXF.rangeFilter_left__3IQwO {
    left: -0.5rem; }
  .rangeFilter_filter__1xIXF.rangeFilter_right__3mJDU {
    right: -0.5rem; }

.rangeFilter_range__2Rsqy {
  width: 100%;
  display: flex; }

.rangeFilter_input__1pAKl {
  width: 50%;
  outline: none;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  padding: 1.6rem 2rem; }
  .rangeFilter_input__1pAKl:first-child {
    border-right: 1px solid var(--color__grey-lighter);
    border-top-left-radius: var(--border-radius__md); }
  .rangeFilter_input__1pAKl:last-child {
    border-top-right-radius: var(--border-radius__md); }

.rangeFilter_actions__3jZkq {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem; }
  .rangeFilter_actions__3jZkq .rangeFilter_button__16KPV {
    padding: 0.5rem 4rem;
    width: 12rem; }
    .rangeFilter_actions__3jZkq .rangeFilter_button__16KPV:first-child {
      margin-right: 1rem; }

.select_select__j6Cui {
  display: block;
  width: 100%;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  font-size: 1.2rem;
  color: var(--color__font-dark);
  padding: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC1SURBVHgB7ZGxDcIwEEXPIelSpExkG5kNsgIbhA1gBDZgFDZgBI8CkhuXlkhhyULmrkCKTCBFuihPcnP/7ulsA6wsACllV9e1+pVTJpumS+vsS8T5HbIMQgh7a+0jlRRFoSHG6tn3O4d8siwVbQAO1EgDw80qJM/zG2YKGDsPJaMbEYrz9sWYxgFHm3nvXVmWGptbrJ2MMdfJq43JYozun2QSfK+jFCLS2QpxgTnQL5IQVhbIG7UePovT+OwqAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  padding-right: 20px; }

.select_select__j6Cui:disabled {
  color: var(--color__font-subtitle); }

.select_labelContainer__UXKeg {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0; }
  .select_labelContainer__UXKeg .select_icon__2_skD {
    width: 15px;
    margin-left: 1rem; }

.selectFilter_container__I3hLU {
  position: relative;
  z-index: 10; }

.selectFilter_filter__1ci3s {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial; }
  .selectFilter_filter__1ci3s.selectFilter_left__21_X_ {
    left: -0.5rem; }
  .selectFilter_filter__1ci3s.selectFilter_right__1kmMk {
    right: -0.5rem; }

.selectFilter_search__2oLz4 {
  position: relative; }
  .selectFilter_search__2oLz4 .selectFilter_icon__2-bC6 {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    stroke: var(--color__font-subtitle);
    width: 2rem; }
  .selectFilter_search__2oLz4 .selectFilter_input__3TthX {
    width: 100%;
    display: block;
    overflow: hidden;
    font-size: 1.4rem;
    color: var(--color__font-dark);
    background: var(--color__input-bg);
    border: 1px solid transparent;
    border-radius: var(--border-radius__md) var(--border-radius__md) 0 0;
    padding: 1.6rem 2rem;
    outline: none; }
    .selectFilter_search__2oLz4 .selectFilter_input__3TthX::-webkit-search-cancel-button {
      display: none; }

.selectFilter_list__o2LZf {
  margin: 4px;
  padding: 1rem 1rem 0 1rem;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal; }
  .selectFilter_list__o2LZf::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px; }
  .selectFilter_list__o2LZf::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px; }
  .selectFilter_list__o2LZf::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px; }

.selectFilter_actions__PuKbq {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem; }
  .selectFilter_actions__PuKbq .selectFilter_button__2EvUQ {
    width: 12rem;
    padding: 0.5rem 4rem; }
    .selectFilter_actions__PuKbq .selectFilter_button__2EvUQ:first-child {
      margin-right: 1rem; }

.selectAsyncFilter_container__3O2Vc {
  position: relative;
  z-index: 10; }

.selectAsyncFilter_filter__3Cozh {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial; }
  .selectAsyncFilter_filter__3Cozh.selectAsyncFilter_left__3TNiI {
    left: -0.5rem; }
  .selectAsyncFilter_filter__3Cozh.selectAsyncFilter_right__2MWgu {
    right: -0.5rem; }

.selectAsyncFilter_search__3R6vW {
  position: relative; }
  .selectAsyncFilter_search__3R6vW .selectAsyncFilter_icon__1fvCh {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    stroke: var(--color__font-subtitle);
    width: 2rem; }
  .selectAsyncFilter_search__3R6vW .selectAsyncFilter_input__VhtQG {
    width: 100%;
    display: block;
    overflow: hidden;
    font-size: 1.4rem;
    color: var(--color__font-dark);
    background: var(--color__input-bg);
    border: 1px solid transparent;
    border-radius: var(--border-radius__md) var(--border-radius__md) 0 0;
    padding: 1.6rem 2rem;
    outline: none; }
    .selectAsyncFilter_search__3R6vW .selectAsyncFilter_input__VhtQG::-webkit-search-cancel-button {
      display: none; }

.selectAsyncFilter_list__246St {
  margin: 4px;
  padding: 1rem 1rem 0 1rem;
  min-height: 5rem;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal; }
  .selectAsyncFilter_list__246St::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px; }
  .selectAsyncFilter_list__246St::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px; }
  .selectAsyncFilter_list__246St::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px; }

.selectAsyncFilter_actions__13kw3 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem; }
  .selectAsyncFilter_actions__13kw3 .selectAsyncFilter_button__3GVlN {
    width: 12rem;
    padding: 0.5rem 4rem; }
    .selectAsyncFilter_actions__13kw3 .selectAsyncFilter_button__3GVlN:first-child {
      margin-right: 1rem; }

.selectAsyncFilter_tip__1Sv-w {
  font-size: 1.4rem;
  color: var(--color__font-dark);
  font-weight: 400;
  padding: 5px; }

.servicesHeader_boxHeader__11-Ad {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color__grey-lighter);
  padding-top: 12px;
  padding-bottom: 12px; }
  .servicesHeader_boxHeader__11-Ad p {
    font-size: 14px;
    margin-top: 15px; }

.servicesHeader_serviceItem__1cZh1 {
  display: flex;
  color: var(--color__font-dark); }
  .servicesHeader_serviceItem__1cZh1 .servicesHeader_label__22szX {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    letter-spacing: 0.12px; }

.servicesHeader_serviceItemOpened__3phS4 {
  display: flex;
  color: #f3732f; }
  .servicesHeader_serviceItemOpened__3phS4 :nth-of-type(1) {
    fill: #f3732f; }
  .servicesHeader_serviceItemOpened__3phS4 .servicesHeader_label__22szX {
    font-size: 14px;
    margin-top: 10px;
    margin-left: 10px;
    letter-spacing: 0.12px; }

.servicesHeader_iconContainer__2U72x {
  width: 33px; }
  .servicesHeader_iconContainer__2U72x :first-child {
    margin-left: auto;
    margin-right: auto; }

.spinner_container__2PEFQ {
  text-align: center; }

.spinner_spinner__rNRrs {
  display: inline-block;
  margin: 0.5rem auto;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: var(--color__primary);
  border-left-color: transparent;
  animation: spinner_spinner__rNRrs 0.75s linear infinite; }

@keyframes spinner_spinner__rNRrs {
  to {
    transform: rotate(360deg); } }

.submitActions_actions__2aPnI {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 4rem 3rem 0; }
  .submitActions_actions__2aPnI > * {
    margin-left: 1rem; }

.table_main__1mXED {
  display: inline-block;
  position: relative; }

.table_table__22P7A {
  display: table;
  font-size: 1.4rem;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: var(--border-radius__lg) var(--border-radius__lg) 0 0;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1); }
  .table_table__22P7A.table_xs__t4ZXR {
    min-width: var(--content-width__xs); }
  .table_table__22P7A.table_sm__3DLEo {
    min-width: var(--content-width__sm); }
  .table_table__22P7A.table_lg__3TPSN {
    min-width: var(--content-width__lg); }

.table_header__1zG4C {
  display: table-header-group; }

.table_headerRow__2q9vA {
  display: table-row;
  text-align: left;
  font-weight: 600;
  color: var(--color__font-dark);
  background: var(--color__table-header-bg); }

.table_headerCell__1E5KB {
  display: table-cell;
  vertical-align: middle; }
  .table_headerCell__1E5KB:first-child {
    border-top-left-radius: var(--border-radius__lg); }
  .table_headerCell__1E5KB:last-child {
    border-top-right-radius: var(--border-radius__lg); }
  .table_headerCell__1E5KB .table_headerTitle__2zFjA {
    padding: 1.5rem; }
  .table_headerCell__1E5KB:hover {
    background: var(--color__grey-light);
    cursor: pointer; }

.table_body__1_jvK {
  display: table-row-group;
  background: var(--color__white); }
  .table_body__1_jvK .table_separatorRow__HkqYN {
    display: table-row;
    background-color: var(--color__table-footer-bg);
    height: 30px; }

.table_bodyRow__1arZ8 {
  display: table-row;
  color: var(--color__font-dark);
  text-decoration: none;
  border-bottom: 1px solid var(--color__grey-lighter); }
  .table_bodyRow__1arZ8:hover {
    background: var(--color__grey-lighter);
    cursor: pointer; }
  .table_bodyRow__1arZ8.table_font-big__9oc00 {
    font-size: 2.5rem; }

.table_bodyCell__1Xg2G {
  display: table-cell;
  padding: 1.5rem; }
  .table_bodyCell__1Xg2G.table_left__3Qoua {
    text-align: left; }
  .table_bodyCell__1Xg2G.table_center__tCVuL {
    text-align: center; }
  .table_bodyCell__1Xg2G.table_right__38tAR {
    text-align: right; }

.table_footerRow__SQMxu {
  display: table-row;
  height: 3.5rem;
  background: var(--color__table-footer-bg);
  text-align: left;
  font-weight: 600; }

.table_footerCell__1FKWr {
  display: table-cell;
  padding: 1.5rem;
  background: var(--color__table-footer-bg);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }
  .table_footerCell__1FKWr.table_rightCells__tTMJD {
    text-align: right; }

.table_emptyRow__H9IC5 {
  display: table-row;
  height: 6rem;
  background: #fff; }

.table_infoText__1cU4q {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.table_verticalCenter__1CUCV {
  vertical-align: middle; }

.table_disabled__3P6Kw {
  background: var(--color__grey-lighter); }

.timeFilter_container__3r7wJ {
  position: relative;
  z-index: 10; }

.timeFilter_filter__2FMZU {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial; }
  .timeFilter_filter__2FMZU.timeFilter_left__3pOQY {
    left: -0.5rem; }
  .timeFilter_filter__2FMZU.timeFilter_right__2Ytqn {
    right: -0.5rem; }

.timeFilter_range__16l0b {
  width: 100%;
  display: flex; }

.timeFilter_input__Ym1j2 {
  width: 50%;
  outline: none;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  padding: 1.6rem 2rem; }
  .timeFilter_input__Ym1j2:first-child {
    border-right: 1px solid var(--color__grey-lighter);
    border-top-left-radius: var(--border-radius__md); }
  .timeFilter_input__Ym1j2:last-child {
    border-top-right-radius: var(--border-radius__md); }
  .timeFilter_input__Ym1j2::-webkit-clear-button {
    display: none; }

.timeFilter_actions__2J0VW {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem; }
  .timeFilter_actions__2J0VW .timeFilter_button__32r9a {
    padding: 0.5rem 4rem;
    width: 12rem; }
    .timeFilter_actions__2J0VW .timeFilter_button__32r9a:first-child {
      margin-right: 1rem; }

.title_title__1NJAt {
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--color__font-title);
  padding: 0.5rem 0; }

.title_total__2tmV- {
  color: var(--color__font-subtitle);
  margin-left: 0.5rem; }

.titleSecondary_title__2C9X- {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color__font-dark);
  letter-spacing: 0.15px; }

:root {
  --toggle__switch-on: #00a63c;
  --toggle__switch-off: #fff;
  --toggle__layer-on: #7fd29d;
  --toggle__layer-off: #adadad; }

.toggle_container__2PbdP {
  display: flex;
  align-items: center;
  color: var(--toggle__layer-off);
  font-weight: 600; }
  .toggle_container__2PbdP.toggle_sm__1amks {
    font-size: 12px; }
  .toggle_container__2PbdP.toggle_lg__2ahSu {
    font-size: 14px; }
  .toggle_container__2PbdP.toggle_disabled__2H2jW {
    opacity: 0.6; }
  .toggle_container__2PbdP .toggle_metaOn__2z0iG {
    color: var(--toggle__switch-on); }
  .toggle_container__2PbdP .toggle_metaOff__1woN- {
    color: #000; }

.toggle_toggle__2xLHd {
  position: relative;
  margin: 0 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600; }
  .toggle_toggle__2xLHd.toggle_sm__1amks {
    width: 38px;
    height: 21px; }
  .toggle_toggle__2xLHd.toggle_lg__2ahSu {
    width: 50px;
    height: 26px; }

.toggle_switch__1PmUE,
.toggle_layer__1f86V {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.toggle_switch__1PmUE {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: var(--toggle__switch-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: 0.3s ease-out all; }
  .toggle_switch__1PmUE.toggle_sm__1amks {
    width: 21px;
    height: 21px; }
  .toggle_switch__1PmUE.toggle_lg__2ahSu {
    width: 26px;
    height: 26px; }

.toggle_switchSmall__1qQmz {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 20px;
  height: 20px;
  background-color: var(--toggle__switch-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: 0.3s ease-out all; }

.toggle_layer__1f86V {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--toggle__layer-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: 0.3s ease-out all;
  z-index: 1; }
  .toggle_layer__1f86V.toggle_sm__1amks {
    height: 13px; }
  .toggle_layer__1f86V.toggle_lg__2ahSu {
    height: 18px; }

.toggle_layerSmall__2a8gR {
  width: 100%;
  height: 12px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: var(--toggle__layer-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: 0.3s ease-out all;
  z-index: 1; }

.toggle_checkbox__3ZB-- {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  cursor: pointer; }
  .toggle_checkbox__3ZB--.toggle_disabled__2H2jW {
    cursor: default; }
  .toggle_checkbox__3ZB--:checked + .toggle_switch__1PmUE {
    left: 100%;
    transform: translateX(-100%);
    background-color: var(--toggle__switch-on); }
  .toggle_checkbox__3ZB--:checked ~ .toggle_layer__1f86V {
    background-color: var(--toggle__layer-on); }

.tooltip_tooltip__1FfEz {
  --tooltip__color: #fff;
  --tooltip__bg-color: #000;
  position: relative; }

.tooltip_tooltip__1FfEz {
  margin: 0 1.5rem; }
  .tooltip_tooltip__1FfEz .tooltip_tip__3u1yy {
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    padding: 0.5rem 1rem;
    text-align: center;
    color: var(--tooltip__color);
    background-color: var(--tooltip__bg-color);
    opacity: 0.8;
    border-radius: 0.5rem; }
    .tooltip_tooltip__1FfEz .tooltip_tip__3u1yy::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 5px solid transparent;
      border-top-color: var(--tooltip__bg-color); }
  .tooltip_tooltip__1FfEz:hover .tooltip_tip__3u1yy {
    visibility: visible; }

.tabs_tabGroup__3-PRu {
  display: flex;
  background: #e0e0e0;
  border-radius: 8px 8px 0 0;
  max-width: -webkit-max-content;
  max-width: max-content; }

.tabs_tab__HDUEl {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  position: relative; }
  .tabs_tab__HDUEl:hover {
    cursor: pointer; }
  .tabs_tab__HDUEl:focus {
    outline: 0; }
  .tabs_tab__HDUEl.tabs_active__1llyG:first-child::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .tabs_tab__HDUEl.tabs_active__1llyG:last-child::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 136px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .tabs_tab__HDUEl .tabs_trapezoid__f9ewJ {
    position: absolute;
    top: -1px;
    left: 3px;
    z-index: 2;
    width: 143.5px;
    height: 50px;
    background: #fff;
    transform: perspective(10px) rotateX(1deg);
    border-radius: 8px 8px 0 0;
    box-shadow: 4px -4px 14px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f1712c;
    display: flex;
    align-items: center;
    justify-content: center; }

.tabs_content__22i6S {
  background-color: #fff;
  border-radius: 0 8px 8px 8px; }

.datePicker_datePicker__1gEg_ {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/static/media/calender.e19c68f1.svg);
  background-repeat: no-repeat;
  background-position: right 1.7rem center;
  padding-right: 20px; }

.datePicker_timePicker__qu_KA {
  background-image: url(/static/media/clock.0958aa12.svg); }

.servicesBoxStyles_mainContainer__1pFcS {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

.servicesBoxStyles_container__2VAoI {
  border-radius: 6px; }
  .servicesBoxStyles_container__2VAoI tr:last-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px; }
  .servicesBoxStyles_container__2VAoI tr:last-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px; }
  .servicesBoxStyles_container__2VAoI tr:first-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px; }
  .servicesBoxStyles_container__2VAoI tr:first-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px; }
  .servicesBoxStyles_container__2VAoI tr td:first-child {
    font-size: 14px;
    letter-spacing: 0.12px;
    color: var(--color__font-dark);
    font-weight: 600;
    width: 100px; }

table {
  border-collapse: collapse;
  border-radius: var(--border-radius__lg) var(--border-radius__lg) 0 0; }
  table thead {
    text-align: left;
    background-color: var(--color__grey-lighter);
    border-right: 1px solid var(--color__grey-lighter); }
  table tbody {
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    border-radius: 0px 0px 6px;
    border-collapse: separate; }
  table tr {
    height: 58px; }
  table td {
    padding-right: 1rem;
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
    margin-left: 0px; }
  table th {
    opacity: 0.8;
    color: #1d1c1c;
    font-size: 14px;
    margin-left: 0px; }
  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px; }
  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px; }

.servicesBoxStyles_threeRowcontainer__9EJnm {
  border: 1px solid #ebebeb; }
  .servicesBoxStyles_threeRowcontainer__9EJnm thead tr th {
    width: 33.33333%;
    text-align: left; }
  .servicesBoxStyles_threeRowcontainer__9EJnm td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 20px; }
  .servicesBoxStyles_threeRowcontainer__9EJnm td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px; }
  .servicesBoxStyles_threeRowcontainer__9EJnm tr td:first-child {
    font-size: 14px;
    letter-spacing: 0.12px;
    color: var(--color__font-dark);
    font-weight: 600; }

.servicesBoxStyles_mainTitle__W02Xd {
  font-size: 18px;
  margin-bottom: 15px; }

.servicesBoxStyles_secondTitle__3hJSx {
  font-size: 16px; }

.servicesBoxStyles_input__37Zqe {
  width: 50px; }

.servicesBoxStyles_boxesContainer__3S6vu {
  display: flex;
  justify-content: space-between; }
  .servicesBoxStyles_boxesContainer__3S6vu p {
    width: 50%; }

.servicesBoxStyles_inactiveStatus__K2Tem {
  color: grey; }

.servicesBoxStyles_BoxesComponent__37jTj {
  display: flex;
  justify-content: space-between; }

.servicesBoxStyles_priceInput__2V-Go input {
  width: 80px; }

.servicesBoxStyles_pricePlanInput__2rPXg input {
  width: 80px; }

.servicesBoxStyles_threeRowPriceInput__3X8FM input {
  width: 80px; }

.boxes_container__2Rr9t {
  width: 100%; }
  .boxes_container__2Rr9t thead tr th {
    text-align: left; }
  .boxes_container__2Rr9t tr:first-child td:before {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 12px; }
  .boxes_container__2Rr9t tr:first-child td:after {
    content: '@';
    display: block;
    text-indent: -99999px;
    height: 5px; }
  .boxes_container__2Rr9t tr:first-child td:nth-child(2) img {
    position: absolute;
    top: 32px;
    right: 20.5px; }

.boxes_mainTitle__3-0kD {
  font-size: 18px;
  margin-bottom: 15px; }

.boxes_secondTitle__3R07F {
  font-size: 18px;
  margin: 20px auto; }

.boxes_input__2z_u9 {
  width: 40px; }

.boxes_boxesContainer__1CtHA {
  display: flex;
  justify-content: space-between;
  width: 500px; }
  .boxes_boxesContainer__1CtHA p {
    width: 50%; }

.boxes_inactiveStatus__SmClw {
  color: grey; }

.boxes_BoxesComponent__27toF {
  display: flex;
  justify-content: space-between; }

.boxes_addbox__2z0Va {
  width: 180px;
  height: 40px;
  margin-top: 15px;
  font-size: 12px;
  background-color: #dedcdd; }
  .boxes_addbox__2z0Va :hover {
    cursor: pointer; }

.boxes_rightBoxWraper__FocCv {
  width: 400px;
  display: flex;
  justify-content: space-between; }

.boxes_toggleContainer__1ulTk label {
  display: flex;
  justify-content: space-between;
  width: 175px;
  margin-right: 30px; }
  .boxes_toggleContainer__1ulTk label p {
    font-size: 12px;
    margin-top: 3px; }
    .boxes_toggleContainer__1ulTk label p :hover {
      cursor: pointer; }

.boxes_visibility__3j8-S {
  visibility: hidden; }

.boxes_box__24Tli {
  border-top: 0px;
  margin-left: 16px;
  width: 100%; }

table thead {
  text-align: left;
  background-color: var(--color__grey-lighter);
  border-right: 1px solid var(--color__grey-lighter); }

table tr {
  height: 58px; }

table td {
  padding-left: 1rem;
  opacity: 0.8;
  color: #1d1c1c;
  font-size: 14px;
  position: relative; }

table th {
  padding-left: 1rem;
  opacity: 0.8;
  color: #1d1c1c;
  font-size: 14px; }

table img {
  position: absolute;
  top: 25.5px;
  right: 20.5px; }

.boxes_qrCodeSave__lyFHf {
  color: #f3732f; }
  .boxes_qrCodeSave__lyFHf:hover {
    cursor: pointer; }

button::before {
  margin-left: 20px; }

.boxes_addBox__3HwSX {
  margin: 2rem 1rem 3rem 1.5rem; }

.boxes_titleFont__2FysL {
  font-size: 14px;
  letter-spacing: 0.12px;
  color: var(--color__font-dark);
  font-weight: 600; }
  .boxes_titleFont__2FysL > div {
    white-space: nowrap; }

.boxes_boxSelectContainer__2Of80 {
  display: flex;
  align-items: center; }
  .boxes_boxSelectContainer__2Of80 > span {
    margin-right: 5px; }
  .boxes_boxSelectContainer__2Of80 .boxes_boxSelect__rpQdp {
    background: var(--color__input-bg);
    border: 1px solid transparent;
    border-radius: var(--border-radius__md);
    font-size: 1.2rem;
    color: var(--color__font-dark);
    padding: 1rem 0; }

.boxes_renewKey__A_faD {
  max-width: 50px; }

.refreshButton_refreshIcon__pvJLr {
  width: 20px;
  height: 20px;
  margin: auto; }
  .refreshButton_refreshIcon__pvJLr :hover {
    cursor: pointer; }

.refreshButton_rotateRefreshIcon__2pSL3 {
  animation: refreshButton_rotate-keyframes__2wPnH 3s;
  width: 20px;
  height: 20px;
  margin: auto; }

@keyframes refreshButton_rotate-keyframes__2wPnH {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(1440deg); } }

.authLayout_container__3JCTO {
  width: var(--size__page-width);
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }

.authLayout_contentContainer__223V0 {
  flex: 1 1;
  display: flex; }

.authLayout_main__3Vvnz {
  padding: 3rem;
  flex: 1 1; }

.authLayout_impersonationContainer__24O4R {
  width: 22rem;
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  margin-bottom: 4rem; }

.header_header__28xGP {
  width: 100%;
  height: var(--size__header-height);
  background: linear-gradient(270deg, var(--color__font-title) 0%, var(--color__font-dark) 100%);
  display: flex;
  align-items: center; }

.header_logo__iXBMJ {
  width: var(--size__sidebar-width); }

.header_nav__2vLlR {
  flex: 1 1;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .header_nav__2vLlR > div:only-child {
    margin-left: auto; }

.logo_logo__1l-TE {
  display: block;
  width: 18rem;
  margin: 0 auto; }

.search_container__12aD7 {
  width: 40rem;
  height: 4rem;
  position: relative; }
  .search_container__12aD7 .search_icon__12Z3T {
    width: 1.8rem;
    fill: #fff;
    margin-left: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.search_input__273wm {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  background: rgba(226, 227, 233, 0.1);
  font-size: 1.4rem;
  color: var(--color__grey-light);
  padding-left: 4.3rem;
  transition: 0.2s all;
  outline: none; }
  .search_input__273wm:focus {
    border: 1px solid var(--color__primary); }
  .search_input__273wm::-webkit-search-cancel-button {
    position: relative;
    right: 10px; }

.search_list__J1_sG {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  overflow: hidden; }

.search_item__sVivL {
  padding: 1rem 2rem; }
  .search_item__sVivL:hover {
    background: var(--color__grey-lighter);
    cursor: pointer; }

.search_link__3zJGv {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__font-dark);
  text-decoration: none; }

.search_subtitle__3lZS4 {
  font-size: 1.2rem;
  font-weight: 400; }

.dropdown_container__2YM8V {
  position: relative; }

.dropdown_header__1_OcL {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center; }

.dropdown_icon__3DSzl {
  width: 1.6rem;
  fill: #fff;
  margin-right: 1rem; }

.dropdown_down___pg2G {
  width: 1.6rem;
  stroke: #fff;
  margin-left: 1rem; }

.dropdown_dropdown__3j5_r {
  position: absolute;
  top: 100%;
  right: -1rem;
  z-index: 500;
  background: #fff;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius__md);
  list-style: none;
  text-align: right; }
  .dropdown_dropdown__3j5_r li:not(:last-child) {
    border-bottom: 1px solid var(--color__grey-lighter); }

.dropdown_item__1KdJM {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 1.4rem;
  color: #000;
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 2rem;
  cursor: pointer;
  white-space: nowrap; }
  .dropdown_item__1KdJM:hover, .dropdown_item__1KdJM:focus {
    outline: none;
    text-decoration: underline; }

.sidebar_sidebar__3sX7X {
  width: var(--size__sidebar-width);
  min-height: 100%;
  background: var(--color__sidebar-bg);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1); }

.sidebar_nav__EejBy {
  margin-top: 3.5rem; }

.sidebar_list__3rxhe {
  list-style: none; }

.sidebar_item__Gdap2 {
  display: block; }

.sidebar_link__3SUH- {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__grey);
  text-decoration: none;
  border-left: 4px solid transparent;
  padding: 1rem 0.6rem 1rem 2rem;
  margin-bottom: 1.6rem; }
  .sidebar_link__3SUH- .sidebar_icon__3NaeK {
    width: 1.8rem;
    margin-right: 1rem;
    fill: var(--color__grey); }
  .sidebar_link__3SUH- .sidebar_label__3oBtQ {
    flex: 1 1; }
  .sidebar_link__3SUH-:hover, .sidebar_link__3SUH-:active, .sidebar_link__3SUH-.sidebar_active__3izKG {
    font-weight: 600;
    color: var(--color__primary);
    border-left: 4px solid var(--color__primary); }
    .sidebar_link__3SUH-:hover .sidebar_icon__3NaeK, .sidebar_link__3SUH-:active .sidebar_icon__3NaeK, .sidebar_link__3SUH-.sidebar_active__3izKG .sidebar_icon__3NaeK {
      fill: var(--color__primary); }

.guestLayout_spinner__1JP-C {
  margin-top: 5rem;
  text-align: center; }

.confirmModal_desc__Yw6DW {
  font-size: 1.4rem;
  margin-bottom: 3rem; }

.confirmModal_actions__bBwb5 {
  display: flex;
  justify-content: space-between; }

.modalLayout_modal__AxQpz {
  width: 49rem;
  max-width: 95%;
  background: var(--color__white);
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .modalLayout_modal__AxQpz .modalLayout_close__3jZDE {
    display: inline-block;
    border: none;
    background: none;
    outline: none; }
  .modalLayout_modal__AxQpz .modalLayout_icon__3C3uQ {
    width: 1.8rem;
    height: 1.8rem;
    margin: 2rem;
    fill: var(--color__black);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
    .modalLayout_modal__AxQpz .modalLayout_icon__3C3uQ:hover, .modalLayout_modal__AxQpz .modalLayout_icon__3C3uQ:active, .modalLayout_modal__AxQpz .modalLayout_icon__3C3uQ:focus {
      fill: var(--color__primary); }
  .modalLayout_modal__AxQpz .modalLayout_title__lluyW {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 3rem 4rem 0 4rem; }
  .modalLayout_modal__AxQpz .modalLayout_content__2o8U7 {
    padding: 4rem 4rem 3rem 4rem; }

.modalLayout_withScroll__2CQoZ {
  overflow-y: auto;
  max-height: 95%;
  width: 80rem; }

.defaultModal_desc__2GrQM {
  font-size: 1.4rem;
  text-align: center; }

.defaultModal_actions__2xQ17 {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem; }

.qrCodesAllModal_modal__3sLOp {
  width: 745px;
  max-width: 95%;
  max-height: 95%;
  background: #fff;
  overflow: hidden;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_close__2cFZ5 {
    display: inline-block;
    border: none;
    background: none;
    outline: none; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_icon__1Y5ty {
    width: 18px;
    height: 18px;
    margin: 20px;
    fill: var(--color__black);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
    .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_icon__1Y5ty:hover, .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_icon__1Y5ty:active, .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_icon__1Y5ty:focus {
      fill: var(--color__primary); }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_title__2HWcB {
    font-size: 16px;
    text-align: center;
    padding: 25px;
    font-weight: 600; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_desc__2bvga {
    font-size: 14px;
    text-align: center;
    padding: 15px 25px; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_actions__18hr6 {
    display: flex;
    align-items: center;
    padding: 25px; }
    .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_actions__18hr6 > * {
      margin-right: 2.5rem; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_qrContainer__1veC1 {
    margin: 5px;
    display: flex;
    flex-wrap: wrap; }
  .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_qr__1r_OQ {
    margin: 22px;
    width: 170px; }
    .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_qr__1r_OQ p {
      margin-top: 10px;
      font-size: 12px; }
    .qrCodesAllModal_modal__3sLOp .qrCodesAllModal_qr__1r_OQ :hover {
      cursor: pointer; }

.qrCodeModal_modal__1sLRx {
  width: 375px;
  max-width: 95%;
  max-height: 95%;
  background: #fff;
  overflow: hidden;
  position: fixed;
  z-index: 55;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_close__1KNUW {
    display: inline-block;
    border: none;
    background: none;
    outline: none; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_icon__12xok {
    width: 18px;
    height: 18px;
    margin: 20px;
    fill: var(--color__black);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
    .qrCodeModal_modal__1sLRx .qrCodeModal_icon__12xok:hover, .qrCodeModal_modal__1sLRx .qrCodeModal_icon__12xok:active, .qrCodeModal_modal__1sLRx .qrCodeModal_icon__12xok:focus {
      fill: var(--color__primary); }
  .qrCodeModal_modal__1sLRx .qrCodeModal_title__Fsas6 {
    font-size: 16px;
    text-align: center;
    padding: 25px;
    font-weight: 600; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_desc__18VwK {
    font-size: 14px;
    text-align: center;
    padding: 15px 25px; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_actions__IMe8D {
    display: flex;
    padding: 25px;
    justify-content: center; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_qrContainer__3szZ6 {
    margin: 5px;
    flex-wrap: wrap;
    justify-content: center;
    width: 315px;
    height: 190px; }
    .qrCodeModal_modal__1sLRx .qrCodeModal_qrContainer__3szZ6 p {
      margin-top: 20px;
      font-size: 12px; }
  .qrCodeModal_modal__1sLRx .qrCodeModal_oneQrContainer__2B7xc {
    width: 320px;
    height: 215px; }

.exportModal_desc__3_V_A {
  font-size: 1.4rem;
  text-align: center; }

.exportModal_actions__2VfS8 {
  display: flex;
  justify-content: space-between;
  padding: 2.5rem; }

.exportModal_downloadText__2y05f {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.confirmCampaignModal_desc__1GyAA {
  margin-top: -28px;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  line-height: 18px; }

.confirmCampaignModal_actions___CMyd {
  display: flex;
  justify-content: space-between; }

.errorModal_modal__vI9k1 {
  width: 60rem;
  max-width: 95%;
  background: var(--color__white);
  border-radius: var(--border-radius__sm);
  border-left: 6px solid var(--color__primary);
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  top: 15%;
  left: 50%;
  transform: translateX(-50%); }
  .errorModal_modal__vI9k1 .errorModal_error__2MK36 {
    font-size: 1.6rem;
    color: var(--color__font-dark);
    padding: 1.5rem; }
  .errorModal_modal__vI9k1 .errorModal_errorSubMessage__JtcLN {
    font-size: 1.2rem;
    color: var(--color__font-dark);
    padding: 0.5rem 1.5rem 1.5rem; }
  .errorModal_modal__vI9k1 .errorModal_button__1-Jnz {
    display: inline-block;
    border: none;
    background: none;
    outline: none; }
  .errorModal_modal__vI9k1 .errorModal_icon__17Nlc {
    width: 3rem;
    margin: 1rem;
    fill: var(--color__primary);
    cursor: pointer; }
    .errorModal_modal__vI9k1 .errorModal_icon__17Nlc:hover, .errorModal_modal__vI9k1 .errorModal_icon__17Nlc:active, .errorModal_modal__vI9k1 .errorModal_icon__17Nlc:focus {
      fill: var(--color__font-dark); }

.login_login__2gh5w {
  display: flex;
  justify-content: center;
  margin-top: 10rem; }
  .login_login__2gh5w .login_button__3gUWp {
    margin: 4rem auto 0 auto; }

.login_container__2S0Q1 {
  padding: 4rem; }

.login_logo__VcTkN {
  display: block;
  width: 100%;
  margin-bottom: 2rem; }

.login_text__UqFy0 {
  color: var(--color__--color__font-dark);
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.4;
  margin-top: 2rem; }

.login_link__3KRRf {
  margin-left: 1rem;
  display: inline-block;
  color: var(--color__primary);
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  background: transparent;
  border: none;
  cursor: pointer; }
  .login_link__3KRRf:hover {
    text-decoration: underline; }

.resetPassRequest_container__1l6F4 .resetPassRequest_info__2elSJ {
  line-height: 1.4;
  font-size: 1.4rem;
  text-align: left; }

.resetPassRequest_container__1l6F4 .resetPassRequest_button__2l3fq {
  margin: 4rem 0 0 auto; }

.memberships_datepickerLabel__2Ryf9 {
  padding: 1rem 0;
  text-align: left; }

.memberships_actions__2UPkN {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem; }

.memberships_table__kO3k1 {
  padding: 0 4rem 4rem 4rem; }

.memberships_addActions__1vRcm {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem; }

.memberships_formdata__LIfPn {
  padding: 0 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem; }

.memberships_formdataVertical__3JjVt {
  border-bottom: 1px solid var(--color__grey-lighter);
  display: grid;
  grid-template-columns: repeat(1, 3fr); }

.memberships_infoRow__7KJx0 {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .memberships_infoRow__7KJx0 > span:first-child {
    width: 10rem;
    font-weight: 600; }
  .memberships_infoRow__7KJx0 .memberships_infoTip__1eHH5 {
    display: inline-block;
    width: 25px; }

.memberships_flexWrapper__1Nz_U {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  align-items: flex-end; }
  .memberships_flexWrapper__1Nz_U[class*='top'] {
    align-items: flex-start; }

.memberships_flexItem__3Vyal {
  width: calc((100% - 20px) / 2); }
  .memberships_flexItem__3Vyal div[class*='react-datepicker-wrapper'] {
    width: 100%; }
  .memberships_flexItem__3Vyal select {
    height: 5.2rem; }

.memberships_historyForm__1BgkY {
  width: 100%;
  height: 100%;
  text-align: start; }

.memberships_formCheckbox__2DAoY div[class*='checkbox'] {
  text-align: start; }

.memberships_formCheckbox__2DAoY label {
  max-width: calc(100% - 14px) !important; }

.memberships_sectionTitle__VSkJb {
  font-size: 3rem;
  font-weight: 600; }

.memberships_benefits__2NkAo {
  margin-top: 3rem; }

.memberships_labelContainer__i6oow {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0; }

.memberships_actionsWrapper__t9SaJ {
  display: flex;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 2rem; }

.memberships_membershipTypeSelect__zovoJ {
  width: 100%; }
  .memberships_membershipTypeSelect__zovoJ * {
    min-width: calc((100% - 6rem) / 3);
    max-width: -webkit-max-content;
    max-width: max-content; }
  .memberships_membershipTypeSelect__zovoJ select {
    font-size: 1.4rem;
    padding: 1.5rem; }

.memberships_sectionTitle__VSkJb {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; }

.memberships_contentSection__1uqsW {
  padding: 4rem 4rem 0; }
  .memberships_contentSection__1uqsW:first-of-type {
    padding-top: 4px; }

.memberships_actionHelperContainer__2Cx7u {
  padding: 1rem 4rem; }

.memberships_actionHelperText__3mwQP {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding-top: 4px;
  color: var(--color__primary); }

.tableSimple_main__37Qdo {
  border: 1px solid var(--color__grey-lighter);
  border-radius: var(--border-radius__lg);
  overflow: hidden; }

.tableSimple_table__1p328 {
  display: table;
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 100%; }

.tableSimple_header__1sXgO {
  display: table-header-group; }

.tableSimple_headerRow__3cElg {
  display: table-row;
  text-align: left;
  font-weight: 500;
  color: var(--color__font-dark);
  background: var(--color__table-header-bg); }

.tableSimple_headerCell__1yXUZ {
  display: table-cell;
  white-space: nowrap;
  padding: 1rem 2rem; }
  .tableSimple_headerCell__1yXUZ.tableSimple_left__3DRGU {
    text-align: left; }
  .tableSimple_headerCell__1yXUZ.tableSimple_right__2nHLn {
    text-align: right; }

.tableSimple_body__isFm8 {
  display: table-row-group;
  background: var(--color__white); }

.tableSimple_bodyRow__3gplM, .tableSimple_bodyRowWithBorder__3Ctz- {
  display: table-row;
  color: var(--color__font-dark);
  text-decoration: none; }
  .tableSimple_bodyRow__3gplM:hover, .tableSimple_bodyRowWithBorder__3Ctz-:hover {
    background: var(--color__grey-lighter); }

.tableSimple_bodyRowWithBorder__3Ctz- {
  border-bottom: 1px solid var(--color__grey-lighter); }

.tableSimple_bodyCell__fRCPF {
  display: table-cell;
  padding: 1.5rem 2rem; }
  .tableSimple_bodyCell__fRCPF.tableSimple_left__3DRGU {
    text-align: left; }
  .tableSimple_bodyCell__fRCPF.tableSimple_right__2nHLn {
    text-align: right; }

.tableSimple_footerRow__VHiOP {
  padding: 1rem 2rem;
  border-top: 1px solid var(--color__grey-lighter); }

.discounts_formdata__2NZsU {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }

.discounts_inputsColumn__wlQ7y {
  display: flex;
  flex-direction: column; }
  .discounts_inputsColumn__wlQ7y > div {
    width: 100%; }

.discounts_inputsRow__2eHM3 {
  display: flex; }
  .discounts_inputsRow__2eHM3 > div {
    width: 50%; }
    .discounts_inputsRow__2eHM3 > div:first-child {
      padding-right: 2rem; }
    .discounts_inputsRow__2eHM3 > div:last-child {
      padding-left: 2rem; }

.discounts_inputsRowFull__3lVWP {
  display: flex;
  margin-top: 3rem; }
  .discounts_inputsRowFull__3lVWP > div {
    width: 100%; }
    .discounts_inputsRowFull__3lVWP > div:first-child {
      padding-right: 2rem; }
    .discounts_inputsRowFull__3lVWP > div:last-child {
      padding-left: 2rem; }

.discounts_list__2ZDr6 {
  max-height: 40rem;
  overflow-y: auto; }
  .discounts_list__2ZDr6::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px; }
  .discounts_list__2ZDr6::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px; }
  .discounts_list__2ZDr6::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px; }

.discounts_selectionLabel__jpP5W {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 4rem 0 1rem 0; }

.receipts_pdfContainer__12Edh {
  padding: 0.5rem;
  text-align: center; }
  .receipts_pdfContainer__12Edh canvas {
    margin: auto; }

.receipts_secondary__3C4Lh {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem; }

.receipts_tableHeader__1NLlM {
  padding: 1.5rem; }

.invoices_pdfContainer__1J2VW {
  padding: 0.5rem;
  text-align: center; }
  .invoices_pdfContainer__1J2VW canvas {
    margin: auto; }

.invoices_secondary__xakq1 {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem; }

.invoices_tableHeader__ASs1b {
  padding: 1.5rem; }

.invoices_centeredPdf__32tv5 {
  display: flex;
  justify-content: center; }

.invoices_leftHeaderItemsContainer__qDI3f {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between; }

.invoices_infoRow__3e9wX {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .invoices_infoRow__3e9wX:not(:last-child) {
    margin-bottom: 3rem; }
  .invoices_infoRow__3e9wX > span:first-child {
    width: 15rem;
    font-weight: 600; }

.invoices_editEntryInfoRow__2c52G {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .invoices_editEntryInfoRow__2c52G:not(:last-child) {
    margin-bottom: 3rem; }
  .invoices_editEntryInfoRow__2c52G > span:first-child {
    width: 16rem;
    font-weight: 600; }
  .invoices_editEntryInfoRow__2c52G > span:not(:first-child) {
    width: 50%; }
  .invoices_editEntryInfoRow__2c52G > span[class*='height'] {
    height: 51px; }
  .invoices_editEntryInfoRow__2c52G > span[class*='free-width'] {
    width: calc(100% - 16rem); }
  .invoices_editEntryInfoRow__2c52G span[class*='title'] {
    text-align: left; }

.invoices_editInvoiceInfoRow__1JVI9 {
  text-align: left; }
  .invoices_editInvoiceInfoRow__1JVI9 > span:last-child {
    display: inline-flex;
    width: 100%; }
    .invoices_editInvoiceInfoRow__1JVI9 > span:last-child > div {
      width: 100%; }

.invoices_submitContainer__2TFVl {
  display: flex;
  justify-content: flex-end;
  margin: 0 4rem 2rem 2rem; }
  .invoices_submitContainer__2TFVl button {
    margin-bottom: 10px; }

.invoices_document__4nhPJ {
  padding: 4rem; }

.invoices_info__39CoF {
  margin: 0 0 0 0; }
  .invoices_info__39CoF [class*='select'] {
    height: 100%; }

.invoices_saveEntry__3zJuh {
  margin-top: 4rem; }

.invoices_entriesContent__rJmi0 {
  width: 100%; }
  .invoices_entriesContent__rJmi0 [class*='table_main'],
  .invoices_entriesContent__rJmi0 [class*='table_table'] {
    width: 100%; }

.invoices_entriesEditTitle__37-xK {
  font-size: 18px;
  font-style: normal;
  font-weight: 600; }

.invoices_entriesEditHeader__3vvcs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem; }

.invoices_selectInput__wkjLz select {
  font-size: 1.4rem;
  padding: 1.5rem; }

.locations_buttonContainer__o4zw9 {
  display: flex;
  justify-content: space-between; }

.locations_tabGroup__3nBLl {
  display: flex;
  background: #dedcdd;
  border-radius: 10px 10px 0 0; }

.locations_tab__qUi9j {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  position: relative; }
  .locations_tab__qUi9j:hover {
    cursor: pointer; }
  .locations_tab__qUi9j:focus {
    outline: 0; }
  .locations_tab__qUi9j.locations_active__2UUbw:first-child::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .locations_tab__qUi9j.locations_active__2UUbw:last-child::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 136px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .locations_tab__qUi9j .locations_trapezoid__3vKOR {
    position: absolute;
    top: -1px;
    left: 3px;
    z-index: 2;
    width: 143.5px;
    height: 50px;
    background: #fff;
    transform: perspective(10px) rotateX(1deg);
    border-radius: 10px 10px 0 0;
    box-shadow: 4px -4px 14px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center; }

.locations_timeRangeButton__2V6yh {
  height: 42px;
  width: 180px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1); }
  .locations_timeRangeButton__2V6yh:hover {
    cursor: pointer; }

.addWasher_container___sEJq {
  padding: 4rem 4rem 0rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }

.googleMaps_googleMapsWindow__3lOZN {
  margin-top: 10px;
  height: 300px;
  width: 300px;
  border: 1px solid grey; }

.googleMaps_mapsInput__1Ktdc {
  width: 100%; }

.googleMaps_searchBox__2V9VT {
  width: 100%; }

.googleMaps_underSearchBox__UuXp0 p {
  font-size: 1.4rem;
  margin-top: 15px;
  color: #1d1c1c; }

.googleMaps_mapSearchInput__1zmLD {
  background: var(--color__input-bg);
  border: 2px solid transparent;
  border-radius: var(--border-radius__md);
  display: block;
  width: 100%;
  font-size: 1.4rem;
  color: var(--color__font-dark);
  padding: 1.5rem;
  z-index: 10;
  outline: 0; }

.googleMaps_mapContainer__-k08I {
  position: relative; }

.googleMaps_mapElement__1d0zJ {
  position: relative;
  margin-top: 15px;
  height: 320px;
  border-radius: var(--border-radius__md);
  z-index: 2;
  background: #fff; }

.googleMaps_searchBoxWrapper__3yG5f {
  background: #fff; }

.services_servicesContainer__T9G_K {
  margin-top: 20px;
  width: 100%; }
  .services_servicesContainer__T9G_K p {
    font-size: 14px; }
  .services_servicesContainer__T9G_K td,
  .services_servicesContainer__T9G_K th {
    font-size: 14px; }

.orders_secondary__YPwqU {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem; }

.orders_document__ie1m4 {
  padding: 4rem; }

.orders_info__249Yz {
  margin: 4rem 0; }

.orders_infoRow__12FcC {
  margin-bottom: 3rem;
  display: flex;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .orders_infoRow__12FcC > span:first-child {
    width: 16rem;
    font-weight: 600; }

.orders_bold__928Sn {
  font-weight: 600; }

.orders_tableHeader__NAE2n {
  margin-left: 1.5rem; }

.orders_tableFooter__7E9EQ {
  text-align: right;
  padding: 2rem 0 0 0; }

.orders_summary__Pg5vw {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  margin-bottom: 2rem; }
  .orders_summary__Pg5vw > span:last-child {
    display: inline-block;
    min-width: 7rem;
    margin-left: 1.5rem; }

.orders_discountSummary__2pAoU {
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .orders_discountSummary__2pAoU > span:last-child {
    display: inline-block;
    min-width: 7rem;
    margin-left: 1.5rem; }

.orders_labelContainer__1qlaM {
  padding: 0rem 0 1rem 0;
  text-align: left; }

.orders_submit__lADYG {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end; }

.orders_colorGrey__4d5zn {
  color: var(--color__grey); }

.orders_paymentInfoTitle__3lsMZ {
  margin: 2rem 0rem; }

.membershipOrders_document__1oxd9 {
  padding: 4rem; }

.membershipOrders_info__38ftx {
  margin: 4rem 0 0 0; }

.membershipOrders_infoRow__3PDOH {
  display: flex;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .membershipOrders_infoRow__3PDOH:not(:last-child) {
    margin-bottom: 3rem; }
  .membershipOrders_infoRow__3PDOH > span:first-child {
    width: 18rem;
    font-weight: 600; }

.clients_document__2rx4g {
  padding: 4rem; }

.clients_info__1JiSl {
  margin: 4rem 0 0 0; }

.clients_infoRow__ZBg6R {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .clients_infoRow__ZBg6R:not(:last-child) {
    margin-bottom: 3rem; }
  .clients_infoRow__ZBg6R > span:first-child {
    width: 15rem;
    font-weight: 600; }

.clients_editMembershipRow__36ViG {
  text-align: left; }
  .clients_editMembershipRow__36ViG > span:last-child {
    display: inline-flex;
    width: 100%; }
    .clients_editMembershipRow__36ViG > span:last-child > div {
      width: 100%; }

.clients_topUpRow__3x546 {
  display: flex;
  flex-direction: row;
  width: 65%;
  grid-column-gap: 2rem;
  column-gap: 2rem; }

.clients_membershipRow__2Pw4d {
  display: flex;
  align-items: start; }

.clients_membershipItem__33xlB {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }

.clients_membershipRowTitle__3uM2P {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.25;
  width: 15rem;
  padding-top: 0.5rem; }

.clients_membershipTitle__AIOFF {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  width: 24.5rem; }

.clients_addMembershipContainer__EUGxK {
  margin-top: 2.4rem; }
  .clients_addMembershipContainer__EUGxK > button {
    padding-left: 0; }

.clients_tableHeader__1rN9S {
  padding: 1.5rem; }

.clients_leftHeaderItemsContainer__3j7Mg {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between; }

.clients_submitContainer__LfTcO {
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem; }

.textile_actionButtons__2raCB {
  display: flex;
  flex-direction: row; }

.textile_actionsHeader__2UwGE {
  text-align: center; }

.businessClients_document__sNJTA {
  padding: 4rem; }

.businessClients_info__hozi5 {
  margin: 0 0 0 0; }
  .businessClients_info__hozi5 [class*='select'] {
    height: 100%; }

.businessClients_financeInfo__1mFlP .businessClients_infoRow__2iL3q > span:not(:first-child) {
  margin-left: 3rem; }

.businessClients_infoRow__2iL3q {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .businessClients_infoRow__2iL3q:not(:last-child) {
    margin-bottom: 3rem; }
  .businessClients_infoRow__2iL3q > span:first-child {
    width: 16rem;
    font-weight: 600; }
  .businessClients_infoRow__2iL3q > span:not(:first-child) {
    width: 50%; }
  .businessClients_infoRow__2iL3q > span[class*='height'] {
    height: 51px; }
  .businessClients_infoRow__2iL3q > span[class*='free-width'] {
    width: calc(100% - 16rem); }
  .businessClients_infoRow__2iL3q span[class*='title'] {
    text-align: left; }

.businessClients_topUpRow__1eR26 {
  display: flex;
  flex-direction: row;
  width: 65%; }

.businessClients_membershipRow__2_7tK {
  display: flex;
  align-items: center; }
  .businessClients_membershipRow__2_7tK > div {
    margin-right: 5rem; }

.businessClients_tableHeader__1YoUf {
  padding: 1.5rem; }

.businessClients_leftHeaderItemsContainer__2tcjI {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between; }

.businessClients_dateRange__3FZzZ {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .businessClients_dateRange__3FZzZ .businessClients_separator__3Itiv {
    margin-left: 2rem;
    margin-right: 2rem; }

.businessClients_submitContainer__2dDRT {
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem 2rem 2rem; }
  .businessClients_submitContainer__2dDRT button {
    margin: 10px; }

.businessClients_buttonContainer__2mftc {
  display: flex;
  justify-content: space-between; }

.businessClients_tabGroup__Jo1dP {
  display: flex;
  background: #e0e0e0;
  border-radius: 8px 8px 0 0;
  max-width: -webkit-max-content;
  max-width: max-content; }

.businessClients_tab__o2DZq {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  position: relative; }
  .businessClients_tab__o2DZq:hover {
    cursor: pointer; }
  .businessClients_tab__o2DZq:focus {
    outline: 0; }
  .businessClients_tab__o2DZq.businessClients_active__2MhX1:first-child::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .businessClients_tab__o2DZq.businessClients_active__2MhX1:last-child::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 136px;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    border-top: 50px solid var(--color__grey-lighter); }
  .businessClients_tab__o2DZq .businessClients_trapezoid__CUViQ {
    position: absolute;
    top: -1px;
    left: 3px;
    z-index: 2;
    width: 143.5px;
    height: 50px;
    background: #fff;
    transform: perspective(10px) rotateX(1deg);
    border-radius: 8px 8px 0 0;
    box-shadow: 4px -4px 14px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f1712c;
    display: flex;
    align-items: center;
    justify-content: center; }

.businessClients_timeRangeButton__3L69a {
  height: 42px;
  width: 180px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1); }
  .businessClients_timeRangeButton__3L69a:hover {
    cursor: pointer; }

.businessClients_membershipsContent__2voKY {
  width: 100%; }
  .businessClients_membershipsContent__2voKY [class*='table_main'],
  .businessClients_membershipsContent__2voKY [class*='table_table'] {
    width: 100%; }

.businessClients_saveMembership__38jMz {
  margin-left: auto;
  margin-top: 4rem; }

.businessClients_membershipEditTitle__h3z6L {
  font-size: 18px;
  font-style: normal;
  font-weight: 600; }

.businessClients_membershipEditHeader__3bEWe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem; }

.businessClients_selectInput__2syfE select {
  font-size: 1.4rem;
  padding: 1.5rem; }

.admins_form__b6Bsr .admins_formdata__3YNOY {
  display: flex;
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }

.admins_form__b6Bsr .admins_inputsContainer__3JZDB {
  width: 50%; }

.admins_form__b6Bsr .admins_inputs__3oJwo {
  width: 35rem; }

.admins_form__b6Bsr .admins_permissions__3Ny1m {
  width: 50%;
  padding-left: 5rem;
  border-left: 1px solid var(--color__grey-lighter); }

.admins_form__b6Bsr .admins_permissionsLabel__uOKUf {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  margin: 2.5rem 0; }

.admins_form__b6Bsr .admins_businessRoleSelect__2IhCq select {
  font-size: 1.4rem;
  padding: 1.5rem; }

.updatePass_inputs__2G5_s {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }
  .updatePass_inputs__2G5_s > div {
    width: 35rem; }

.updatePass_msg__Otn0V {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  text-align: right;
  padding: 1rem 4rem 0 4rem; }

.resetPassFromToken_login__1B18Q {
  display: flex;
  justify-content: center;
  margin-top: 10rem; }
  .resetPassFromToken_login__1B18Q .resetPassFromToken_button__2MyBv {
    margin: 4rem auto 0 auto; }

.resetPassFromToken_container__2H8Oe {
  padding: 4rem; }

.resetPassFromToken_logo__3GvcI {
  display: block;
  width: 100%;
  margin-bottom: 2rem; }

.employees_document__2rImK {
  padding: 4rem; }

.employees_info__3SPA- {
  margin: 1rem 0 0 0; }

.employees_infoRow__1LV5Q {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .employees_infoRow__1LV5Q:not(:last-child) {
    margin-bottom: 3rem; }
  .employees_infoRow__1LV5Q > span:first-child {
    width: 16rem;
    font-weight: 600; }
  .employees_infoRow__1LV5Q > span:not(:first-child) {
    width: 20rem; }

.employees_tableHeader__1DHqB {
  padding: 1.5rem; }

.employees_leftHeaderItemsContainer__1YiOi {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between; }

.employees_submitContainer__3YQXp {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2rem 2rem 0; }

.employees_physicalCardToggler__1Euaz {
  display: flex;
  justify-content: end;
  padding: 10px 25px 0px 0px; }

.orderHistory_document__UPS8L {
  padding: 4rem; }

.orderHistory_info__3yu5_ {
  margin: 1rem 0 0 0; }

.orderHistory_infoRow__2B0Tq {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .orderHistory_infoRow__2B0Tq:not(:last-child) {
    margin-bottom: 3rem; }
  .orderHistory_infoRow__2B0Tq > span:first-child {
    width: 16rem;
    font-weight: 600; }

.orderHistory_tableHeader__14n9D {
  padding: 1.5rem; }

.orderHistory_leftHeaderItemsContainer__3FOwx {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between; }

.businessInvoices_centeredPdf__3gr7F {
  display: flex;
  justify-content: center; }

.twoFactorLogin_login__14b2B {
  display: flex;
  justify-content: center;
  margin-top: 10rem; }
  .twoFactorLogin_login__14b2B .twoFactorLogin_button__12vD2 {
    margin: 4rem auto 0 auto; }

.twoFactorLogin_container__2-ILn {
  padding: 4rem; }

.twoFactorLogin_logo__1mLAl {
  display: block;
  width: 100%;
  margin-bottom: 2rem; }

.twoFactorLogin_text__2FyQ6 {
  color: var(--color__--color__font-dark);
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.4;
  margin-top: 2rem; }

.twoFactorLogin_link__3HONg {
  margin-left: 1rem;
  display: inline-block;
  color: var(--color__primary);
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  background: transparent;
  border: none;
  cursor: pointer; }
  .twoFactorLogin_link__3HONg:hover {
    text-decoration: underline; }

.coupons_tableHeader__3i9aL {
  padding: 1.5rem; }

.discounts_document__3PgzP {
  padding: 4rem; }

.discounts_info__goJvT {
  margin: 1rem 0 0 0; }

.discounts_infoRow__2d-MG {
  display: flex;
  align-items: center;
  color: var(--color__font-dark);
  font-size: 1.4rem; }
  .discounts_infoRow__2d-MG:not(:last-child) {
    margin-bottom: 1rem; }

.discounts_submitContainer__1zR43 {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2rem 2rem 0; }

.campaignNotifications_formdata__NtHn4 {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }

.campaignNotifications_tableHeader__3Exz1 {
  padding: 1.5rem; }

.campaignNotifications_inputsColumn__1Erbw {
  display: flex;
  flex-direction: column; }
  .campaignNotifications_inputsColumn__1Erbw > div {
    width: 100%; }

.campaignNotifications_contentSection__2ZEvE {
  padding-bottom: 2rem; }

.campaignNotifications_potentialAudienceSection__a1pKj {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter); }
  .campaignNotifications_potentialAudienceSection__a1pKj p {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color__font-dark); }

.campaignNotifications_notificiationTypeSelect__3jXtX {
  width: 100%; }
  .campaignNotifications_notificiationTypeSelect__3jXtX * {
    min-width: calc((100% - 6rem) / 3);
    max-width: -webkit-max-content;
    max-width: max-content; }
  .campaignNotifications_notificiationTypeSelect__3jXtX select {
    font-size: 1.4rem;
    padding: 1.5rem; }

.campaignNotifications_labelContainer__2ysh1 {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0; }

.campaignNotifications_inputsRow__2pqLn {
  display: flex; }
  .campaignNotifications_inputsRow__2pqLn > div {
    width: 50%; }
    .campaignNotifications_inputsRow__2pqLn > div:first-child {
      padding-right: 2rem; }
    .campaignNotifications_inputsRow__2pqLn > div:last-child {
      padding-left: 2rem; }

.campaignNotifications_inputsRowFull__12lTA {
  display: flex;
  margin-top: 3rem; }
  .campaignNotifications_inputsRowFull__12lTA > div {
    width: 100%; }
    .campaignNotifications_inputsRowFull__12lTA > div:first-child {
      padding-right: 2rem; }
    .campaignNotifications_inputsRowFull__12lTA > div:last-child {
      padding-left: 2rem; }

.campaignNotifications_list__goeYS {
  max-height: 40rem;
  overflow-y: auto; }
  .campaignNotifications_list__goeYS::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px; }
  .campaignNotifications_list__goeYS::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px; }
  .campaignNotifications_list__goeYS::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px; }

.campaignNotifications_selectionLabel__3-irX {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 4rem 0 1rem 0; }

.campaignNotifications_segments__11wTr {
  padding: 2rem 4rem; }

.campaignNotifications_segmentsInputLabel__2h2q3 {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem; }
  .campaignNotifications_segmentsInputLabel__2h2q3:not(:first-child) {
    padding-top: 2rem; }

.campaignNotifications_segmentsContainer__3KKUo {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }

.campaignNotifications_actions__3zewa {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 4rem 3rem 0; }
  .campaignNotifications_actions__3zewa > * {
    margin-left: 1rem; }

.segments_segmentsGroup__9wlLZ {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1); }
  .segments_segmentsGroup__9wlLZ .segments_segmentsGroupHandle__2ugBG {
    width: 40px;
    border-top-left-radius: var(--border-radius__md);
    border-bottom-left-radius: var(--border-radius__md);
    background-color: var(--color__input-bg); }
  .segments_segmentsGroup__9wlLZ .segments_segmentsGroupContent__17QSp {
    flex: 1 1; }

.segments_segment__3Tl9J {
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  .segments_segment__3Tl9J:not(:last-child) {
    margin-bottom: 2rem; }
  .segments_segment__3Tl9J .segments_segmentHeader__3M2cN {
    width: 100%;
    display: flex;
    align-items: flex-start; }

.segments_segmentTypeSelect__3-I8P {
  width: 100%; }
  .segments_segmentTypeSelect__3-I8P * {
    min-width: calc((100% - 6rem) / 3);
    max-width: 230px; }
  .segments_segmentTypeSelect__3-I8P select {
    font-size: 1.4rem;
    padding: 1.5rem;
    line-height: 17px; }

.segments_frequencyTypeSelect__3CuW6 {
  width: 100%; }
  .segments_frequencyTypeSelect__3CuW6 * {
    min-width: calc((100% - 6rem) / 3);
    max-width: -webkit-max-content;
    max-width: max-content; }
  .segments_frequencyTypeSelect__3CuW6 select {
    font-size: 1.4rem;
    padding: 1.5rem; }

.segments_labelContainer__QILjG {
  display: flex;
  align-items: center;
  padding-bottom: 1rem; }

.segments_segmentDivision__1YM5h {
  width: 100%;
  height: 1px;
  position: relative;
  margin-bottom: 2rem;
  background-color: var(--color__font-title); }
  .segments_segmentDivision__1YM5h .segments_segmentDivisionTitle__2k7rs {
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 1px 4px;
    background-color: white; }
    .segments_segmentDivision__1YM5h .segments_segmentDivisionTitle__2k7rs h2 {
      line-height: normal; }
  .segments_segmentDivision__1YM5h.segments_divisionTypeOr__35SN5 {
    margin-bottom: 1rem;
    background-color: var(--color__grey-light); }
    .segments_segmentDivision__1YM5h.segments_divisionTypeOr__35SN5 .segments_segmentDivisionTitle__2k7rs {
      top: -10px; }
      .segments_segmentDivision__1YM5h.segments_divisionTypeOr__35SN5 .segments_segmentDivisionTitle__2k7rs h2 {
        font-size: 1.4rem; }

.segments_segmentForm__257zr {
  width: 100%;
  margin-top: 1rem; }
  .segments_segmentForm__257zr .segments_inputsRow__1ej97 {
    display: flex; }
    .segments_segmentForm__257zr .segments_inputsRow__1ej97 > div {
      width: 50%; }
      .segments_segmentForm__257zr .segments_inputsRow__1ej97 > div:first-child {
        padding-right: 1rem; }
      .segments_segmentForm__257zr .segments_inputsRow__1ej97 > div:last-child {
        padding-left: 1rem; }
  .segments_segmentForm__257zr .segments_selectWrapper__3n94r {
    width: 50%;
    height: auto;
    max-height: 220px;
    overflow: scroll; }
    .segments_segmentForm__257zr .segments_selectWrapper__3n94r > div {
      margin: 1.2rem 0; }
  .segments_segmentForm__257zr.segments_moneySpent__2bcnb .segments_inputsRow__1ej97 > div > div {
    padding-top: 1rem; }
  .segments_segmentForm__257zr.segments_moneySpent__2bcnb .segments_inputsRow__1ej97 input {
    height: 46px; }

