.container {
  width: 40rem;
  height: 4rem;
  position: relative;

  .icon {
    width: 1.8rem;
    fill: #fff;
    margin-left: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.input {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  background: rgba(226, 227, 233, 0.1);
  font-size: 1.4rem;
  color: var(--color__grey-light);
  padding-left: 4.3rem;
  transition: 0.2s all;
  outline: none;

  &:focus {
    border: 1px solid var(--color__primary);
  }

  &::-webkit-search-cancel-button {
    position: relative;
    right: 10px;
  }
}

.list {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  overflow: hidden;
}

.item {
  padding: 1rem 2rem;

  &:hover {
    background: var(--color__grey-lighter);
    cursor: pointer;
  }
}

.link {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__font-dark);
  text-decoration: none;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 400;
}
