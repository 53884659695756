.document {
  padding: 4rem;
}

.info {
  margin: 0 0 0 0;

  & [class*='select'] {
    height: 100%;
  }
}

.financeInfo {
  .infoRow {
    > span {
      &:not(:first-child) {
        margin-left: 3rem;
      }
    }
  }
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 16rem;
      font-weight: 600;
    }

    &:not(:first-child) {
      width: 50%;
    }

    &[class*='height'] {
      height: 51px;
    }

    &[class*='free-width'] {
      width: calc(100% - 16rem);
    }
  }

  & span[class*='title'] {
    text-align: left;
  }
}

.topUpRow {
  display: flex;
  flex-direction: row;
  width: 65%;
}

.membershipRow {
  display: flex;
  align-items: center;

  > div {
    margin-right: 5rem;
  }
}

.tableHeader {
  padding: 1.5rem;
}

.leftHeaderItemsContainer {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between;
}

.dateRange {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .separator {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem 2rem 2rem;

  button {
    margin: 10px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.tabGroup {
  display: flex;
  background: #e0e0e0;
  border-radius: 8px 8px 0 0;
  max-width: max-content;
}

.tab {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }

  &.active {
    &:first-child {
      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0px;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 15px solid transparent;
        border-top: 50px solid var(--color__grey-lighter);
      }
    }
    &:last-child {
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 136px;
        top: 0px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 0px solid transparent;
        border-top: 50px solid var(--color__grey-lighter);
      }
    }
  }

  .trapezoid {
    position: absolute;
    top: -1px;
    left: 3px;
    z-index: 2;
    width: 143.5px;
    height: 50px;
    background: #fff;
    transform: perspective(10px) rotateX(1deg);
    border-radius: 8px 8px 0 0;
    box-shadow: 4px -4px 14px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f1712c;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.timeRangeButton {
  height: 42px;
  width: 180px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
}

.membershipsContent {
  width: 100%;

  & [class*='table_main'],
  & [class*='table_table'] {
    width: 100%;
  }
}

.saveMembership {
  margin-left: auto;
  margin-top: 4rem;
}

.membershipEditTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.membershipEditHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}

.selectInput {
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}
