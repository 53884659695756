.document {
  padding: 4rem;
}

.info {
  margin: 4rem 0 0 0;
}

.infoRow {
  display: flex;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 18rem;
      font-weight: 600;
    }
  }
}
