.container {
  position: relative;
}

.header {
  padding: 0.5rem 0;
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  width: 1.6rem;
  fill: #fff;
  margin-right: 1rem;
}

.down {
  width: 1.6rem;
  stroke: #fff;
  margin-left: 1rem;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: -1rem;
  z-index: 500;
  background: #fff;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius__md);
  list-style: none;
  text-align: right;

  li:not(:last-child) {
    border-bottom: 1px solid var(--color__grey-lighter);
  }
}

.item {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 1.4rem;
  color: #000;
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 2rem;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}
