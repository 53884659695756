.datePicker {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./calender.svg');
  background-repeat: no-repeat;
  background-position: right 1.7rem center;
  padding-right: 20px;
}

.timePicker {
  background-image: url('./clock.svg');
}
