.pdfContainer {
  padding: 0.5rem;
  text-align: center;

  canvas {
    margin: auto;
  }
}

.secondary {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem;
}

.tableHeader {
  padding: 1.5rem;
}
