.formdata {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);
}

.inputsColumn {
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
}

.inputsRow {
  display: flex;

  > div {
    width: 50%;

    &:first-child {
      padding-right: 2rem;
    }
    &:last-child {
      padding-left: 2rem;
    }
  }
}

.inputsRowFull {
  display: flex;
  margin-top: 3rem;

  > div {
    width: 100%;

    &:first-child {
      padding-right: 2rem;
    }
    &:last-child {
      padding-left: 2rem;
    }
  }
}

.list {
  max-height: 40rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px;
  }
}

.selectionLabel {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 4rem 0 1rem 0;
}
