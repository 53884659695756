.modal {
  width: 60rem;
  max-width: 95%;
  background: var(--color__white);
  border-radius: var(--border-radius__sm);
  border-left: 6px solid var(--color__primary);
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);

  .error {
    font-size: 1.6rem;
    color: var(--color__font-dark);
    padding: 1.5rem;
  }

  .errorSubMessage {
    font-size: 1.2rem;
    color: var(--color__font-dark);
    padding: 0.5rem 1.5rem 1.5rem;
  }

  .button {
    display: inline-block;
    border: none;
    background: none;
    outline: none;
  }

  .icon {
    width: 3rem;
    margin: 1rem;
    fill: var(--color__primary);
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      fill: var(--color__font-dark);
    }
  }
}
