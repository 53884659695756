.select {
  display: block;
  width: 100%;
  background: var(--color__input-bg);
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  font-size: 1.2rem;
  color: var(--color__font-dark);
  padding: 1rem;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./arrow.png');
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  padding-right: 20px;
}

.select:disabled {
  color: var(--color__font-subtitle);
}

.labelContainer {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0;

  .icon {
    width: 15px;
    margin-left: 1rem;
  }
}
