.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  height: 4.5rem;

  &.lg {
    width: var(--content-width__lg);
  }

  &.sm {
    width: var(--content-width__sm);
  }

  &.custom {
    width: 68%;
  }
}
