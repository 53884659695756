.segmentsGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: var(--border-radius__lg);
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.1);
  .segmentsGroupHandle {
    width: 40px;
    border-top-left-radius: var(--border-radius__md);
    border-bottom-left-radius: var(--border-radius__md);
    background-color: var(--color__input-bg);
  }
  .segmentsGroupContent {
    flex: 1;
  }
}

.segment {
  padding: 1.5rem 2rem;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .segmentHeader {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
}

.segmentTypeSelect {
  width: 100%;

  & * {
    min-width: calc((100% - 6rem) / 3);
    max-width: 230px;
  }
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
    line-height: 17px;
  }
}

.frequencyTypeSelect {
  width: 100%;

  & * {
    min-width: calc((100% - 6rem) / 3);
    max-width: max-content;
  }
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.segmentDivision {
  width: 100%;
  height: 1px;
  position: relative;
  margin-bottom: 2rem;
  background-color: var(--color__font-title);
  .segmentDivisionTitle {
    position: absolute;
    top: -12px;
    left: 15px;
    padding: 1px 4px;
    background-color: white;
    h2 {
      line-height: normal;
    }
  }
  &.divisionTypeOr {
    margin-bottom: 1rem;
    background-color: var(--color__grey-light);
    .segmentDivisionTitle {
      top: -10px;
      h2 {
        font-size: 1.4rem;
      }
    }
  }
}

.segmentForm {
  width: 100%;
  margin-top: 1rem;
  .inputsRow {
    display: flex;

    > div {
      width: 50%;

      &:first-child {
        padding-right: 1rem;
      }
      &:last-child {
        padding-left: 1rem;
      }
    }
  }

  .selectWrapper {
    width: 50%;
    height: auto;
    max-height: 220px;
    overflow: scroll;
    > div {
      margin: 1.2rem 0;
    }
  }

  &.moneySpent {
    .inputsRow {
      > div {
        > div {
          padding-top: 1rem;
        }
      }
      input {
        height: 46px;
      }
    }
  }
}
