.title {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  max-width: 12rem;

  > span {
    word-wrap: break-word;
  }

  .icon {
    width: 1rem;
    margin-left: 0.5rem;
    fill: var(--color__font-dark);
  }

  &.active {
    .icon {
      fill: var(--color__primary);
    }
  }
}
