.main {
  border: 1px solid var(--color__grey-lighter);
  border-radius: var(--border-radius__lg);
  overflow: hidden;
}

.table {
  display: table;
  border-collapse: collapse;
  font-size: 1.4rem;
  width: 100%;
}

.header {
  display: table-header-group;
}

.headerRow {
  display: table-row;
  text-align: left;
  font-weight: 500;
  color: var(--color__font-dark);
  background: var(--color__table-header-bg);
}

.headerCell {
  display: table-cell;
  white-space: nowrap;
  padding: 1rem 2rem;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.body {
  display: table-row-group;
  background: var(--color__white);
}

.bodyRow {
  display: table-row;
  color: var(--color__font-dark);
  text-decoration: none;

  &:hover {
    background: var(--color__grey-lighter);
  }
}

.bodyRowWithBorder {
  @extend .bodyRow;
  border-bottom: 1px solid var(--color__grey-lighter);
}

.bodyCell {
  display: table-cell;
  padding: 1.5rem 2rem;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}

.footerRow {
  padding: 1rem 2rem;
  border-top: 1px solid var(--color__grey-lighter);
}
