.datepickerLabel {
  padding: 1rem 0;
  text-align: left;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.table {
  padding: 0 4rem 4rem 4rem;
}

.addActions {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
}

.formdata {
  padding: 0 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

.formdataVertical {
  // padding: 0 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);

  display: grid;
  grid-template-columns: repeat(1, 3fr);
}

.infoRow {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  > span {
    &:first-child {
      width: 10rem;
      font-weight: 600;
    }
  }

  .infoTip {
    display: inline-block;
    width: 25px;
  }
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  align-items: flex-end;
  &[class*='top'] {
    align-items: flex-start;
  }
}

.flexItem {
  width: calc((100% - 20px) / 2);
  div[class*='react-datepicker-wrapper'] {
    width: 100%;
  }
  select {
    height: 5.2rem;
  }
}

.historyForm {
  width: 100%;
  height: 100%;
  text-align: start;
}

.formCheckbox {
  & div[class*='checkbox'] {
    text-align: start;
  }
  & label {
    max-width: calc(100% - 14px) !important;
  }
}

.sectionTitle {
  font-size: 3rem;
  font-weight: 600;
}

.benefits {
  margin-top: 3rem;
}

.labelContainer {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0;
}

.actionsWrapper {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 2rem;
}

.membershipTypeSelect {
  width: 100%;

  & * {
    min-width: calc((100% - 6rem) / 3);
    max-width: max-content;
  }
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}

.sectionTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contentSection {
  padding: 4rem 4rem 0;
  &:first-of-type {
    padding-top: 4px;
  }
}

.actionHelperContainer {
  padding: 1rem 4rem;
}

.actionHelperText {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding-top: 4px;
  color: var(--color__primary);
}
