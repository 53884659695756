.container {
  .info {
    line-height: 1.4;
    font-size: 1.4rem;
    text-align: left;
  }

  .button {
    margin: 4rem 0 0 auto;
  }
}
