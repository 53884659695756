.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 4rem 3rem 0;

  > * {
    margin-left: 1rem;
  }
}
