:root {
  --color__primary: #f1712c;
  --color__primary-disabled: #fdac81;
  --color__dim: #fde9de;

  --color__white: #fff;
  --color__black: #000;

  --color__grey-lighter: #ebeaeb;
  --color__grey-light: #aeaeaf;
  --color__grey: #6a696b;
  --color__grey-dark: #48474b;
  --color__grey-darker: #373738;

  --color__sidebar-bg: #fefefe;
  --color__font-dark: #1d1c1c;
  --color__font-title: #404042;
  --color__font-subtitle: #8a8789;
  --color__table-header-bg: #eeedee;
  --color__table-footer-bg: #f6f6f8;
  --color__input-bg: #f2f1f1;

  --color__border: #a5a4a4;

  --color__success: #00a63c;
  --color__success-bg: #e6f6ec;
  --color__danger: #d22d2d;
  --color__danger-bg: #f7d6d7;
  --color__warning: #fab600;

  --color__status-blue: #00b6da;
  --color__status-green: #00a63c;
  --color__status-red: #f20000;
  --color__status-orange: #ff6a1b;
  --color__status-yellow: #f2b920;
  --color__status-dark-grey: #1d1c1c;
  --color__status-dark-green: #007028;

  --size__page-width: 154rem;
  --size__sidebar-width: 22rem;
  --size__header-height: 8rem;

  --border-radius__sm: 4px;
  --border-radius__md: 6px;
  --border-radius__lg: 8px;

  --content-width__xs: 50rem;
  --content-width__sm: 88rem;
  --content-width__lg: 100%;
}
