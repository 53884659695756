.sidebar {
  width: var(--size__sidebar-width);
  min-height: 100%;
  background: var(--color__sidebar-bg);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.nav {
  margin-top: 3.5rem;
}

.list {
  list-style: none;
}

.item {
  display: block;
}

.link {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color__grey);
  text-decoration: none;
  border-left: 4px solid transparent;
  padding: 1rem 0.6rem 1rem 2rem;
  margin-bottom: 1.6rem;

  .icon {
    width: 1.8rem;
    margin-right: 1rem;
    fill: var(--color__grey);
  }

  .label {
    flex: 1;
  }

  &:hover,
  &:active,
  &.active {
    font-weight: 600;
    color: var(--color__primary);
    border-left: 4px solid var(--color__primary);

    .icon {
      fill: var(--color__primary);
    }
  }
}
