.button {
  --button__primary-text: #fff;
  --button__primary-bg: var(--color__primary);
  --button__primary-hover: #eb5d10;

  --button__primary-disabled-bg: var(--color__primary-disabled);

  --button__dim-text: var(--color__primary);
  --button__dim-bg: var(--color__dim);
  --button__dim-hover: #fcdece;

  --button__light-text: var(--color__grey-darker);
  --button__light-bg: #d4d2d3;
  --button__light-hover: #c3c1c2;

  --button__dark-text: #fff;
  --button__dark-bg: var(--color__grey-darker);
  --button__dark-hover: #484849;

  --button__outline-text: var(--color__grey-darker);
  --button__outline-hover: rgba(0, 0, 0, 0.05);
}

.button {
  padding: 1.4rem 4.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius__md);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 2rem;
    margin-right: 1rem;
  }

  &:focus {
    outline: none;
  }

  &.primary {
    color: var(--button__primary-text);
    background: var(--button__primary-bg);
    .icon {
      stroke: var(--button__primary-text);
    }
    &:hover,
    &:focus,
    &:active {
      background: var(--button__primary-hover);
    }
  }

  &.dim {
    color: var(--button__dim-text);
    background: var(--button__dim-bg);
    .icon {
      stroke: var(--button__dim-text);
    }
    &:hover,
    &:focus,
    &:active {
      background: var(--button__dim-hover);
    }
  }

  &.light {
    color: var(--button__light-text);
    background: var(--button__light-bg);
    .icon {
      stroke: var(--button__light-text);
    }
    &:hover,
    &:focus,
    &:active {
      background: var(--button__light-hover);
    }
  }

  &.dark {
    color: var(--button__dark-text);
    background: var(--button__dark-bg);
    .icon {
      stroke: var(--button__dark-text);
    }
    &:hover,
    &:focus,
    &:active {
      background: var(--button__dark-hover);
    }
  }

  &.outline {
    color: var(--button__outline-text);
    border: 1px solid var(--button__outline-text);
    background: transparent;
    .icon {
      stroke: var(--button__outline-text);
    }
    &:hover,
    &:focus,
    &:active {
      background: var(--button__outline-hover);
    }
  }

  &:disabled {
    background: var(--button__primary-disabled-bg);

    &:hover {
      background: var(--button__primary-disabled-bg);
      cursor: default;
    }
  }
}

.transparent {
  background: transparent;
  color: black;
  text-decoration: underline;
  width: 50px;
}
