.title {
  font-size: 2.4rem;
  font-weight: 600;
  color: var(--color__font-title);
  padding: 0.5rem 0;
}

.total {
  color: var(--color__font-subtitle);
  margin-left: 0.5rem;
}
