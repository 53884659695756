.alert {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  margin: 0.4rem 0;
  border-radius: var(--border-radius__sm);
  display: inline-block;

  .icon {
    display: inline-block;
    width: 1.5rem;
    margin-right: 1rem;
  }

  &.success {
    color: var(--color__success);
    background: var(--color__success-bg);
  }

  &.danger {
    color: var(--color__danger);
    background: var(--color__danger-bg);
  }
}
