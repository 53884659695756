.servicesContainer {
  margin-top: 20px;
  width: 100%;
  p {
    font-size: 14px;
  }

  td,
  th {
    font-size: 14px;
  }
}
