.tabGroup {
  display: flex;
  background: #e0e0e0;
  border-radius: 8px 8px 0 0;
  max-width: max-content;
}

.tab {
  width: 150px;
  height: 50px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }

  &.active {
    &:first-child {
      &::before {
        content: '';
        position: absolute;
        left: -20px;
        top: 0px;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 15px solid transparent;
        border-top: 50px solid var(--color__grey-lighter);
      }
    }
    &:last-child {
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 136px;
        top: 0px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 0px solid transparent;
        border-top: 50px solid var(--color__grey-lighter);
      }
    }
  }

  .trapezoid {
    position: absolute;
    top: -1px;
    left: 3px;
    z-index: 2;
    width: 143.5px;
    height: 50px;
    background: #fff;
    transform: perspective(10px) rotateX(1deg);
    border-radius: 8px 8px 0 0;
    box-shadow: 4px -4px 14px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f1712c;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content {
  background-color: #fff;
  border-radius: 0 8px 8px 8px;
}
