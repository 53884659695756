.formdata {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);
}

.tableHeader {
  padding: 1.5rem;
}

.inputsColumn {
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
}

.contentSection {
  padding-bottom: 2rem;
}

.potentialAudienceSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);
  p {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color__font-dark);
  }
}

.notificiationTypeSelect {
  width: 100%;

  & * {
    min-width: calc((100% - 6rem) / 3);
    max-width: max-content;
  }
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}

.labelContainer {
  display: flex;
  align-items: center;
  padding: 2.5rem 0 1rem 0;
}

.inputsRow {
  display: flex;

  > div {
    width: 50%;

    &:first-child {
      padding-right: 2rem;
    }
    &:last-child {
      padding-left: 2rem;
    }
  }
}

.inputsRowFull {
  display: flex;
  margin-top: 3rem;

  > div {
    width: 100%;

    &:first-child {
      padding-right: 2rem;
    }
    &:last-child {
      padding-left: 2rem;
    }
  }
}

.list {
  max-height: 40rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px;
  }
}

.selectionLabel {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 4rem 0 1rem 0;
}

.segments {
  padding: 2rem 4rem;
}

.segmentsInputLabel {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1rem;
  &:not(:first-child) {
    padding-top: 2rem;
  }
}

.segmentsContainer {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 4rem 3rem 0;

  > * {
    margin-left: 1rem;
  }
}
