.document {
  padding: 4rem;
}

.info {
  margin: 1rem 0 0 0;
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.submitContainer {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 2rem 2rem 0;
}
