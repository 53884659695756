.pdfContainer {
  padding: 0.5rem;
  text-align: center;

  canvas {
    margin: auto;
  }
}

.secondary {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.6rem;
}

.tableHeader {
  padding: 1.5rem;
}

.centeredPdf {
  display: flex;
  justify-content: center;
}

.leftHeaderItemsContainer {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between;
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 15rem;
      font-weight: 600;
    }
  }
}

.editEntryInfoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 16rem;
      font-weight: 600;
    }

    &:not(:first-child) {
      width: 50%;
    }

    &[class*='height'] {
      height: 51px;
    }

    &[class*='free-width'] {
      width: calc(100% - 16rem);
    }
  }

  & span[class*='title'] {
    text-align: left;
  }
}

.editInvoiceInfoRow {
  text-align: left;

  > span {
    &:last-child {
      display: inline-flex;
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
}

.submitContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 4rem 2rem 2rem;

  button {
    margin-bottom: 10px;
  }
}

.document {
  padding: 4rem;
}

.info {
  margin: 0 0 0 0;

  & [class*='select'] {
    height: 100%;
  }
}

.saveEntry {
  margin-top: 4rem;
}

.entriesContent {
  width: 100%;

  & [class*='table_main'],
  & [class*='table_table'] {
    width: 100%;
  }
}

.entriesEditTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.entriesEditHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}

.selectInput {
  select {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
}
