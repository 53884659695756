.document {
  padding: 4rem;
}

.info {
  margin: 1rem 0 0 0;
}

.infoRow {
  display: flex;
  align-items: center;

  color: var(--color__font-dark);
  font-size: 1.4rem;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  > span {
    &:first-child {
      width: 16rem;
      font-weight: 600;
    }
  }
}

.tableHeader {
  padding: 1.5rem;
}

.leftHeaderItemsContainer {
  width: var(--content-width__sm);
  display: flex;
  justify-content: space-between;
}
