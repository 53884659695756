.login {
  display: flex;
  justify-content: center;
  margin-top: 10rem;

  .button {
    margin: 4rem auto 0 auto;
  }
}

.container {
  padding: 4rem;
}

.logo {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}

.text {
  color: var(--color__--color__font-dark);
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.4;
  margin-top: 2rem;
}

.link {
  margin-left: 1rem;
  display: inline-block;
  color: var(--color__primary);
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
