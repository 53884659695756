@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

*,
*::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 10px
  box-sizing: border-box;
  line-height: 1;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: var(--color__grey-lighter);
}
