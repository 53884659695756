.container {
  position: relative;
  z-index: 10;
}

.filter {
  position: absolute;
  top: calc(100% - 0.5rem);
  background: #fff;
  border-radius: var(--border-radius__md);
  box-shadow: 0 2px 54px 0 rgba(0, 0, 0, 0.25);
  min-width: 27.5rem;
  cursor: initial;

  &.left {
    left: -0.5rem;
  }

  &.right {
    right: -0.5rem;
  }
}

.search {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    stroke: var(--color__font-subtitle);
    width: 2rem;
  }

  .input {
    width: 100%;
    display: block;
    overflow: hidden;
    font-size: 1.4rem;
    color: var(--color__font-dark);
    background: var(--color__input-bg);
    border: 1px solid transparent;
    border-radius: var(--border-radius__md) var(--border-radius__md) 0 0;
    padding: 1.6rem 2rem;
    outline: none;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.list {
  &::-webkit-scrollbar {
    background-color: var(--color__grey-lighter);
    height: 4px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color__grey-lighter);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color__grey-light);
    border-radius: 10px;
  }

  margin: 4px;
  padding: 1rem 1rem 0 1rem;
  min-height: 5rem;
  max-height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 1rem;

  .button {
    width: 12rem;
    padding: 0.5rem 4rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.tip {
  font-size: 1.4rem;
  color: var(--color__font-dark);
  font-weight: 400;
  padding: 5px;
}
