@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
:root {
  --color__primary: #f1712c;
  --color__primary-disabled: #fdac81;
  --color__dim: #fde9de;
  --color__white: #fff;
  --color__black: #000;
  --color__grey-lighter: #ebeaeb;
  --color__grey-light: #aeaeaf;
  --color__grey: #6a696b;
  --color__grey-dark: #48474b;
  --color__grey-darker: #373738;
  --color__sidebar-bg: #fefefe;
  --color__font-dark: #1d1c1c;
  --color__font-title: #404042;
  --color__font-subtitle: #8a8789;
  --color__table-header-bg: #eeedee;
  --color__table-footer-bg: #f6f6f8;
  --color__input-bg: #f2f1f1;
  --color__border: #a5a4a4;
  --color__success: #00a63c;
  --color__success-bg: #e6f6ec;
  --color__danger: #d22d2d;
  --color__danger-bg: #f7d6d7;
  --color__warning: #fab600;
  --color__status-blue: #00b6da;
  --color__status-green: #00a63c;
  --color__status-red: #f20000;
  --color__status-orange: #ff6a1b;
  --color__status-yellow: #f2b920;
  --color__status-dark-grey: #1d1c1c;
  --color__status-dark-green: #007028;
  --size__page-width: 154rem;
  --size__sidebar-width: 22rem;
  --size__header-height: 8rem;
  --border-radius__sm: 4px;
  --border-radius__md: 6px;
  --border-radius__lg: 8px;
  --content-width__xs: 50rem;
  --content-width__sm: 88rem;
  --content-width__lg: 100%; }

*,
*::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  box-sizing: border-box;
  line-height: 1; }

body {
  font-family: 'Open Sans', sans-serif;
  background: var(--color__grey-lighter); }

html {
  -webkit-text-size-adjust: 100%; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  background-color: transparent; }

a,
button {
  -webkit-tap-highlight-color: transparent; }

b,
strong {
  font-weight: bolder; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

small {
  font-size: 75%; }

img {
  border-style: none;
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

label {
  display: inline-block; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit; }

select {
  word-wrap: normal; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

[hidden] {
  display: none; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }
