.checkbox {
  line-height: 22px;
  margin: 1.5rem 0;

  input[type='checkbox'] {
    opacity: 0;

    + label::after {
      content: none;
    }

    &:checked + label::before {
      background: var(--color__primary);
      border: 1px solid var(--color__primary);
    }

    &:checked + label::after {
      content: '';
    }

    &:focus + label::before {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  .labelFullLine {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 1.4rem;
    &.success {
      font-weight: bold;
    }
    color: var(--color__font-dark);
    left: -7px;
  }

  label:not(.labelFullLine) {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    font-size: 1.4rem;
    &.success {
      font-weight: bold;
    }
    color: var(--color__font-dark);
    left: -7px;
    max-width: 300px;
  }

  label::before {
    content: '';
    display: inline-block;
    height: 18px;
    width: 18px;
    border: 1px solid var(--color__border);
    border-radius: 2px;

    position: absolute;
    top: 3px;
    left: 0px;
  }

  label::after {
    content: '';
    display: inline-block;
    height: 5px;
    width: 9px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);

    position: absolute;
    top: 8px;
    left: 5px;
  }
}
