.inputs {
  padding: 0 4rem 3rem 4rem;
  border-bottom: 1px solid var(--color__grey-lighter);

  > div {
    width: 35rem;
  }
}

.msg {
  color: var(--color__font-dark);
  font-size: 1.4rem;
  text-align: right;
  padding: 1rem 4rem 0 4rem;
}
