:root {
  --toggle__switch-on: #00a63c;
  --toggle__switch-off: #fff;

  --toggle__layer-on: #7fd29d;
  --toggle__layer-off: #adadad;
}

.container {
  display: flex;
  align-items: center;
  color: var(--toggle__layer-off);
  font-weight: 600;

  &.sm {
    font-size: 12px;
  }

  &.lg {
    font-size: 14px;
  }

  &.disabled {
    opacity: 0.6;
  }

  .metaOn {
    color: var(--toggle__switch-on);
  }

  .metaOff {
    color: #000;
  }
}

.toggle {
  position: relative;
  margin: 0 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  &.sm {
    width: 38px;
    height: 21px;
  }

  &.lg {
    width: 50px;
    height: 26px;
  }
}

.switch,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: var(--toggle__switch-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: 0.3s ease-out all;

  &.sm {
    width: 21px;
    height: 21px;
  }

  &.lg {
    width: 26px;
    height: 26px;
  }
}

.switchSmall {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 20px;
  height: 20px;
  background-color: var(--toggle__switch-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: 0.3s ease-out all;
}

.layer {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--toggle__layer-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: 0.3s ease-out all;
  z-index: 1;

  &.sm {
    height: 13px;
  }

  &.lg {
    height: 18px;
  }
}

.layerSmall {
  width: 100%;
  height: 12px;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: var(--toggle__layer-off);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  transition: 0.3s ease-out all;
  z-index: 1;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &:checked {
    + .switch {
      left: 100%;
      transform: translateX(-100%);
      background-color: var(--toggle__switch-on);
    }

    ~ .layer {
      background-color: var(--toggle__layer-on);
    }
  }
}
